.als-news-detail
	.side-social-wrap 
		@apply pt-4
		@media screen and ( max-width: 1024px)
			@apply start-item
		@screen lg
			@apply absolute top-0 left-[-60px] z-30 
		li
			&:not(:last-child)
				@apply mr-3
				@screen lg
					@apply mb-[10px] mr-0
		a
			@apply center-item rounded-full transition
			border: 1px solid #E7e7e7
			width: 40px
			height: 40px
			em
				@apply text-grey-500 leading-none transition
				font-size: 16px
			&:hover
				@apply bg-blue-primary
				em
					@apply text-white 
	time
		font-size: 14px
	.fullcontent
		@apply pt-4 border-t border-t-green-CDEDDD mt-3
	.zone-title
		margin-bottom: r(16px)
		@apply text-blue-primary font-normal
		@screen lg
			margin-bottom: r(24px)
	.news-side 

		.headline
			a
				+clamp(18px,24px,1.3,1)
		.zone-time
			@apply mb-2
		time,.zone-type
			font-size: 12px
		.desc
			+clamp(14px,18px,1.3,2)
	.other-news
		