section.menu-date
	z-index: 2
	@apply bg-blue-E6EFF7 relative overflow-hidden
	+overlay-3
	.btn-selection
		@apply flex items-center relative ml-auto w-full md:w-fit
		@apply min-w-[304px]
		select
			@apply appearance-none pl-5 pr-5 md:px-12 w-full text-center
		&::after
			content: "\f078"
			@apply font-Awesome6 absolute right-6  text-[14px]
	