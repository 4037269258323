=img-ratio($ratio,$fit:cover)
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: $ratio *100%
	img
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: $fit
		transition: .3s ease-in-out all
=boxshadow
	box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09)
=list
	li
		@apply start-item mb-16
		align-items: flex-start
		em
			@apply inline-block  mr-4 text-blue-primary
			font-size: 16px
		span,p
			@apply text-grey-700 font-normal leading-tight mb-0
			word-break: break-word
			+fz(15px,18px)
=overlay($bottom:-30px)
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0  z-10  transition 
		width: 310px
		height: 360px
		background: url('../img/overlay/1.svg')
		background-repeat: no-repeat
		background-size: 100% 100%
		background-position: center
		bottom: $bottom
=overlay-3($left:-30px,$bottom:-30px)
	&::after
		content: ''
		width: 449px
		height: 595px
		background: url('../img/overlay/3.svg')
		background-repeat: no-repeat
		background-size: 100% 100%
		top: 20vh
		left: 0
		z-index: -1
		@apply block absolute pointer-events-none transition 
		@media (max-width: 767.98px)
			width: 220px
		