.primary-banner
	@apply relative overflow-hidden
	.banner-container
		@apply relative
		@media screen and ( max-width: 1200px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 550/750 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.txt-wrap
		@apply  z-30
		@screen sm
			@apply absolute-center text-left
		@media screen and ( max-width: 576px)
			@apply absolute-x bottom-[50px] text-center

	.wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full 
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)
	.img
		@apply relative
		@media screen and ( min-width: 1200.1px)
			a
				+img-ratio(860/1920)
		@media screen and ( max-width: 1200px)
			a
				+img-ratio(550/750)
	.pagination-wrap
		@apply z-30 
		@screen xl
			@apply absolute-center 
		@media screen and ( max-width: 1200px)
			@apply absolute-x bottom-[30px]  center-item
		@media screen and ( max-width: 576px)
			@apply bottom-[15px]
	.swiper-pagination
		@screen xl
			width: fit-content !important
			@apply center-item ml-3
		@media screen and ( max-width: 1200px)
			@apply w-full center-item
	.title
		@apply font-normal leading-tight text-white font-Judson 
		@screen sm
			padding-bottom: 150px
		+fz(24px,32px)
		*
			@apply font-normal leading-tight text-white font-Judson
			+fz(24px,32px)
		@screen lg
			max-width: 50%
		@media screen and ( max-width: 576px)
			+fz(20px,24px)
			*
				+fz(20px,24px)

	.swiper-pagination-bullet
		@apply  transition opacity-50 center-item bg-white relative  overflow-hidden 
		border-radius: 0 !important
		height: 4px
		width: 20px
		&.swiper-pagination-bullet-active
			@apply opacity-100 border-white
			width: 60px