.menu-list
	@apply bg-blue-E6EFF7 relative overflow-hidden
	+overlay-3
	&::after
		bottom: unset
		top: r(340px)
	.container
		@apply relative z-40
	.wrap-block-title
		@apply mb-8
		@screen lg
			margin-bottom: r(50px)
	.menu-item-nutrition
		@apply h-full
		.txt
			@apply h-full
	.row
		.col
			.menu-item-nutrition
				.txt
					@apply bg-white
				.headline
					a
						@apply text-blue-primary
				&:hover
					.headline
						a
							@apply text-green-primary
		@media screen and ( max-width: 576px)
			margin: 0 -5px -10px -5px
			.col
				padding: 0 5px
				margin-bottom: 10px
.menu-item-nutrition
	@apply rounded-[20px] overflow-hidden
	.img
		a
			+img-ratio(312/416)
	.txt
		@apply bg-white
		padding: r(20px)
		@screen lg
			padding: r(26px) r(20px)
	.menu-hover
		ul
			li
				&:hover
					@apply text-blue-primary
	.content
		ul
			gap: r(8px)
			@apply list-disc pl-5 flex flex-col
		li
			font-size: r(14px)
			color: #4D4D4D
			line-height: 1.4
			&::marker
				font-size: 12px
				color: #4D4D4D
	.headline
		a
			@apply text-blue-primary