.back-to-top
	@apply  fixed bottom-[40px] right-[30px] bg-white rounded-full center-item z-50
	width:  r(60px)
	height: r( 60px)
	@media screen and ( max-width: 1024px)
		width: 40px
		height: 40px
	em
		@apply text-blue-primary
		font-size: r(24px)
		@media screen and ( max-width: 1024px)
			font-size: 20px
footer
.footer-top
	@apply bg-blue-CCDEEE
	padding: r(50px) 0
	@screen lg
		padding: r(50px) 0 r(90px) 0
	.headline
		@apply text-blue-primary mb-16
		+fz(22px,24px)
		*
			@apply text-blue-primary
			+fz(22px,24px)
	ul
		li
			@apply font-normal text-grey-700  leading-none font-Bai
			+fz(14px,18px)
			*
				@apply font-normal text-grey-700  leading-none font-Bai
				+fz(14px,18px)
			&:not(:last-child)
				@apply mb-4
			&:hover
				a
					@apply underline
.footer-bottom
	padding: r(30px) 0 0 0 
	.container
		@apply col-ver
	.copyright,.site-title
		@apply text-grey-700 font-medium leading-normal py-3 text-center 
		font-size: 14px
		@media screen and ( max-width: 768px)
			@apply pt-4 leading-normal  text-center
		*
			@apply text-grey-700 font-medium leading-normal
			font-size: 14px
	.site-title
		@apply pb-0
	.wrap-right
		@apply items-center  col-ver
		@media screen and ( max-width: 768px)
			@apply col-ver
	.policy-nav
		@apply start-item pt-4
		a
			@apply text-grey-700 font-medium leading-none
			font-size: 14px
			*
				@apply text-grey-700 font-medium leading-none
				font-size: 14px
		li
			@apply pr-3 leading-none
			&:hover
				a
					@apply underline
			&:last-child
				@apply pr-0
			&:not(:first-child)
				@apply relative
				&::before
					content: ''
					@apply inline-block absolute bottom-[3px] left-[-8px]  pointer-events-none z-10  transition bg-grey-500
					height: 2px
					width: 2px 
.logo
	a
		@apply start-item
		height: 80px
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain