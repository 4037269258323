.policy-list
	.tab-nav
		@apply bg-transparent
		margin-bottom: r(40px)
		@media screen and ( max-width: 1024px)
			@apply justify-start overflow-x-auto
			ul
				@apply whitespace-nowrap justify-start
		@screen lg
			margin-bottom: r(50px)
		ul
			@apply bg-transparent
			box-shadow: none
		li
			@apply bg-transparent
			&.active
				a
					@apply text-blue-primary
					&::after
						@apply opacity-100
		a
			background: white !important
			@apply text-grey-300 font-bold relative uppercase
			&::after
				content: ''
				@apply block absolute-x pointer-events-none  z-10 bg-blue-primary opacity-0  transition w-full h-[1px] bottom-0
		
	.title-wrap
		@apply gap-item
		@media screen and ( max-width: 1024px)
			@apply col-ver
		.block-title
			@apply text-grey-700
		.btn-view-more
			em
				@apply ml-3
	.fullcontent
		@apply border-t border-t-grey-100 pt-4
		h2
			@apply text-grey-700 font-Judson !#{important} 
		h3,h4,h5
			+fz(15px,18px)
		ul,ol
			list-style-type: none
			padding-left: 0
			li
				@apply relative
				&:before
					@apply text-grey-700 
					content: '-'
					display: inline-block
					pointer-events: none
					z-index: 9
					height: 16px
					width: 16px
					transition: .3s all ease-in-out
		img
			@apply overflow-hidden rounded-[20px]
			@screen lg
				@apply rounded-[40px]