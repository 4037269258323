.wrap-form
	@apply relative
	.row
		box-shadow: none !important
	.fa-exclamation-triangle
		@apply absolute font-light text-primary
		font-size: 12px
		font-family: 'Montserrat', sans-serif
		bottom: -20px
		left: 15px
		@media screen and ( max-width: 576px)
			font-size: 10px
			@apply font-bold
	.col
		@apply mb-4 relative
		&:last-child
			@apply mb-6
	.form-group
		@apply relative 
		margin-bottom: 30px !important
		@media screen and ( max-width: 576px)
			margin-bottom: 25px !important
		input[type='text'],textarea,select
			height: 44px
			@apply text-white bg-transparent border-b border-b-white border-opacity-20
			font-size: clamp(13px,r(14px),14px)
			@media screen and ( max-width: 1200px)
				font-size: clamp(14px,r(16px),16px)
				height: 40px
			&::placeholder
				@apply  font-normal text-white
				font-size: clamp(13px,r(14px),14px)
				@media screen and ( max-width: 1200px)
					font-size: clamp(14px,r(16px),16px)
	.form-group
		&.form-select
			@apply relative overflow-hidden
			&::after
				content: ''
				@apply block absolute-y pointer-events-none right-[35px]  z-10  transition
				width: r(24px)
				height: 11px
				background-size: contain
				background-repeat: no-repeat
				background-position: center
				background-image: url("data:image/svg+xml;charset=utf-8,<svg width='23' height='11' viewBox='0 0 23 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.1875 0.53125C1.32812 0.34375 1.51562 0.25 1.75 0.25C1.89062 0.25 2.07812 0.296875 2.21875 0.4375L11.4531 8.92188L20.7344 0.4375C21.0156 0.15625 21.4844 0.15625 21.7656 0.484375C22.0469 0.765625 22.0469 1.23438 21.7188 1.51562L11.9688 10.5156C11.6875 10.7969 11.2656 10.7969 10.9844 10.5156L1.23438 1.51562C0.90625 1.28125 0.90625 0.8125 1.1875 0.53125Z' fill='%232F4394'/></svg>")
				@media screen and ( max-width: 1200px)
					width: 16px
					height: 11px
					height: 40px
			select
				height: 44px
				@apply rounded-none w-full  text-grey-700 
				padding-right: 50px !important
				-webkit-appearance: none
				-moz-appearance: none
				-webkit-box-sizing: border-box
				-moz-box-sizing: border-box
				box-sizing: border-box
				-webkit-appearance: none
				-moz-appearance: none
				font-size: clamp(13px,r(14px),14px)
				@media screen and ( max-width: 1200px)
					font-size: clamp(14px,r(16px),16px)
					height: 40px
				option
					font-size: clamp(13px,r(14px),14px)
					@apply  text-grey-700
					@media screen and ( max-width: 1200px)
						font-size: clamp(14px,r(16px),16px)
				&::-ms-expand 
					display: none
		textarea
			@apply p-4 w-full
			height: 7rem
			@screen lg
				height: 120px
	.frm-btnwrap
		@apply start-item w-full mt-6
		@media screen and ( max-width: 1200px)
			@apply mt-8
			margin-bottom: 0 !important
		label
			display: none !important
		.frm-btn
			@apply relative
			&:hover
				&::after
					@apply text-white
				input[type='submit']
					@apply  text-white
			&::after
				font-family: 'Font Awesome 6 Pro'
				content: '\f061'
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[25px]  z-10  transition font-normal
				height: 18px
				width: 20px
				line-height: 18px
				ont-size: clamp(18px,r(20px),20px)
				@media screen and ( max-width: 1024px)
					right: 20px
			input[type='submit']
				width: 110px
				height: 48px
				@apply transition font-medium center-item px-6 pr-12
				font-size: clamp(14px,r(16px),16px)
				@media screen and ( max-width: 1024px) 
					height: 40px
					@apply px-3  pr-8

.frm-btn-reset
	display: none
.frm-captcha
	margin: 40px 0 0  0 !important
	display: inline-flex
	flex-direction: row-reverse
	img
		height: 44px !important
		max-width: auto
		max-width: 150px
		@media screen and ( max-width:1200px)
			max-width: 150px
			
	@media screen and (max-width:576px)
		flex-direction: column-reverse
	.frm-captcha-input
		@apply mt-0
		margin-left: 50px
		@media screen and ( max-width:576px)
			margin-left: 0
			@apply mt-4
		label
			display: none
		input
			padding: 0 10px
			margin-top: 0
			height: 30px
			@media screen and ( max-width:1200px)
				max-width: 200px
			@media screen and ( max-width:1200px)
				max-width: 150px
				
			@media screen and ( max-width:1024px)
				max-width: 200px
				
			@media (max-width: 767.98px)
				margin-left: 20px
			@media (max-width: 576.98px)
				margin-left: 0
	.rcRefreshImage
		position: absolute
		opacity: 1
		cursor: pointer
		height: 0
		width: 0
		right: -5px
		top: 5px
		color: #ffffff
		z-index: 0
		font-size: 0
		&:before
			font-family: 'Font Awesome 6 Pro'
			position: absolute
			top: 0
			right: -40px
			opacity: 1
			z-index: 99
			color: #9d9080
			font-size: 30px
			content: "\f01e"
			display: flex
			justify-content: center
			align-items: flex-start
			@media screen and ( max-width:576px)
				height: 50px
@media screen and ( max-width:500px )
	#ctl00_mainContent_ctl05_captcha_ctl00
		display: none
#ctl00_mainContent_ctl02_captcha_ctl01
	position: relative
#ctl00_mainContent_ctl06_btnReset,#ctl00_mainContent_ctl05_captcha_ctl00,#ctl00_mainContent_ctl08_captcha_ctl00,#ctl00_mainContent_ctl07_captcha_ctl00
	display: none
#ctl00_mainContent_ctl06_txtCaptcha
	margin-left: 10px
#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton
	#text
		display: none
.RadCaptcha
	@apply relative
	@media screen and ( max-width:767.5px)
		margin-left: 0
	span
		@apply hidden
	#ctl00_mainContent_ctl04_captcha_ctl00
		display: block !important
		position: absolute
		left: 0
		top: 100%
		@apply text-primary text-12
	>div
		>div
			display: flex
			position: relative
			flex-direction: column-reverse
			height: 40px !important
			margin-top: 0
			@media (max-width: 767.98px)
				margin-right: 0
				width: 180px