.home-treatment
	@apply bg-blue-E7F6EF
	.title-wrap
		@apply border-b border-b-green-9BDABB 
		padding-bottom: (16px)
		margin-bottom: r(40px)
		@screen lg
			margin-bottom: r(52px)
		.block-title
			@screen lg
				@apply mb-0
		@media screen and ( max-width: 1024px)
			@apply col-ver
.news-item
	.img
		@apply rounded-[20px] overflow-hidden
		a
			+img-ratio(312/416)
	.zone-time
		margin: r(30px) 0 r(20px)
	.desc
		margin: r(16px) 0
		+clamp(14px,18px,1.35,3)
		*
			+clamp(14px,18px,1.35,3)
	&:hover
		.headline
			a
				@apply text-green-primary