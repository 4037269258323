.news-detail 
	padding: r(60px) 0
	@screen lg
		padding: r(100px)  0
	.block-title
		@apply mb-5 py-5  border-b normal-case
.news-side
	@apply start-item
	align-items: flex-start
	padding: r(24px) 0 r(20px)
	@media screen and ( max-width: 576px)
		@apply col-hor
	&:not(:last-child)
		@apply border-b border-b-green-CDEDDD
	&:first-child
		@apply pt-0
	&:hover
		.headline
			a
				@apply underline
	.txt
		padding-right: r(20px)
		@media screen and ( max-width: 576px)
			@apply order-2
		@screen sm
			padding-right: r(30px)
	time,.zone-type
		font-size: 14px
	.zone-time
		@apply mb-4
		@media screen and ( min-width: 576px) and ( max-width: 1600px)
			@apply mb-2
			@apply col-hor
			.zone-type
				@apply pl-0 mt-1
	.headline
		a
			+clamp(19px,24px,1.3,2)
	.img-wrap
		@media screen and ( max-width: 576px)
			@apply w-full mb-4
			order: 1
		a
			@apply overflow-hidden rounded-[20px]
			@media screen and ( max-width: 576px)
				+img-ratio(9/16)
				@apply w-full
			@screen sm
				@apply center-item mr-5
				height: 105px
				width: 140px
				min-width: 140px
				max-width: 140px
				img
					width: 100%
					height: 100%
					object-fit: cover
	
.fullcontent
	@apply font-normal text-grey-700 leading-normal
	+fz(15px,18px)
	*
		@apply font-normal text-grey-700 leading-normal
		+fz(15px,18px)
	img,iframe
		@apply block mx-auto my-4
	p
		@apply mb-3
	ul,ol
		list-style-type: disc
		padding-left: 20px
		li
			@apply mb-2
	h2,h3,h4,h5
		@apply text-blue-primary mb-4 font-bold #{!important} 
	strong
		font-weight: 700 !important
		*
			font-weight: 700 !important
	h2
		+fz(32px,36px)
		@media screen and ( max-width: 576px)
			+fz(26px,28px)
	h3
		+fz(28px,32px)
		@media screen and ( max-width: 576px)
			+fz(24px,28px)
	h4
		+fz(24px,28px)
		@media screen and ( max-width: 576px)
			+fz(20px,24px)
	h5
		+fz(20px,24px)
		@media screen and ( max-width: 576px)
			+fz(16px,20px)
	.row
		@apply mb-0