.fa-light,.fa-regular,.fas,.fa-brands,.fa-thin
	font-style: normal
.fa-phone-circle::before 
	content: "\e11b"
.fa-user-lock::before 
	content: "\f502"
.fa-rss::before 
	content: "\f09e"
.fa-circle-info::before 
	content: "\f05a"
.fa-file-arrow-down::before
	content: "\f56d"
.fa-wrench::before 
	content: "\f0ad"
.fa-wrench::before 
	content: "\f0ad"
.fa-cart-shopping::before 
	content: "\f07a"
.fa-caret-down::before 
	content: "\f0d7"
.fa-caret-up::before 
	content: "\f0d8"
.fa-caret-left::before 
	content: "\f0d9"
.fa-caret-right::before 
	content: "\f0da"
.fa-earth-americas::before 
	content: "\f57d"
.fa-magnifying-glass::before 
	content: "\f002"
.fa-circle-plus::before 
	content: "\f055"
.fa-chevron-left::before 
	content: "\f053"
.fa-chevron-right::before 
	content: "\f054"
.fa-arrow-right::before 
	content: "\f061"
.fa-phone::before 
	content: "\f095"
.fa-comment-dots::before 
	content: "\f4ad"
.fa-location-dot::before 
	content: "\f3c5"
.fa-globe::before 
	content: "\f0ac"
.fa-envelope::before 
	content: "\f0e0"
.fa-phone-arrow-up-right::before 
	content: "\e224"
.fa-phone-rotary::before 
	content: "\f8d3"
.fa-arrow-down-to-bracket::before
	content: "\e094"
.fa-arrow-down::before
	content: "\f063"
.fa-chevron-down::before
	content: "\f078"
.fa-minus::before
	content: "\f068"
.fa-plus::before
	content: "\2b"
.fa-xmark::before
	content: "\f00d"
.fa-lock::before
	content: "\f023"
.fa-facebook-f::before
	content: "\f39e"
.fa-google::before
	content: "\f1a0"
.fa-ellipsis-vertical::before
	content: "\f142"
.fa-ellipsis-vertical::before
	content: "\f142"
.fa-filter::before
	content: "\f0b0"
.fa-arrow-right-to-arc::before
	content: "\e4b2"
.fa-arrow-up::before
	content: "\f062"
.fa-house::before
	content: "\f015"
.fa-long-arrow-left::before
	content: "\f177"
.fa-long-arrow-right::before
	content: "\f178"
.fa-file-pdf::before
	content: "\f1c1"
.fa-linkedin-in::before
	content: "\f0e1"
.fa-computer-mouse-scrollwheel::before
	content: "\f8cd"
.fa-angles-right:before
	content: "\f101"
.fa-angles-left:before
	content: "\f100"
.fa-arrow-to-top:before
	content: "\f341"
.fa-paper-plane-top:before
	content: "\e20a"
.fa-money-check-dollar-pen:before
	content: "\f873"
.fa-seedling:before
	content: "\f4d8"
.fa-cloud:before
	content: "\f0c2"
.fa-people-group:before
	content: "\e533"
.fa-down-to-bracket:before
	content: "\e4e7"
.fa-phone-arrow-up-right:before
	content: "\e224"
.fa-arrow-right-long:before
	content: "\f178"
.fa-timer:before
	content: "\e29e"
.fa-linkedin-in:before
	content: "\f0e1"
.fa-twitter:before
	content: "\f099"
.fa-instagram:before
	content: "\f16d"
.fa-list:before
	content: "\f03a"
.fa-arrow-down-long:before
	content: "\f175"
.fa-fax:before
	content: "\f1ac"
.fa-th-large:before
	content: "\f009"
.fa-x:before
	content: "\58"
.fa-bars-filter:before
	content: "\e0ad"
.fa-chevron-up:before
	content: "\f077"
.fa-youtube:before
	content: "\f167"
.fa-arrow-up-long:before
	content: "\f176"
.fa-arrow-up-down:before
	content: "\f175"
.fa-unlock:before
	content: "\f09c"
.fa-lock:before
	content: "\f023"