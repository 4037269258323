.home-support
	.row
		@media screen and ( max-width: 576px)
			margin: 0 -5px -10px -5px
			.col
				padding: 0 5px
				margin-bottom: 10px
	.block-title
		@apply mb-8
		@screen lg
			margin-bottom: r(40px)
	.item
		@apply relative rounded-[8px] overflow-hidden
		&:hover
			.img
				&::after
					@apply opacity-50
			.icon
				@apply bg-blue-primary
				img
					filter: brightness(0) invert(1)
	.img
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: rgba(#0A0A0A ,.5 )
		a
			+img-ratio(235/415)
			img
				@apply transition
	.txt
		@apply absolute-center w-full h-full col-ver z-30 pointer-events-none
		justify-content: flex-end
		padding: r(20px)
	.icon
		@apply center-item overflow-hidden mx-auto mb-16 bg-white rounded-full p-3
		height: r(80px)
		width: r(80px)
		img
			width: auto
			height: 100%
			object-fit: contain