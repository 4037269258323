.home-about
	@apply relative
	+overlay
	@media screen and ( max-width: 1024px)
		&::after
			@apply top-0
	.container
		@apply relative z-40
	.row
		@apply justify-between
	.img
		@apply rounded-[20px] overflow-hidden
		@screen lg
			@apply rounded-[40px]
		a
			+img-ratio(480/640)
	.block-title
		@media screen and ( max-width: 1024px)
			@apply text-center