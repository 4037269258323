.facility-detail
	@apply relative
	+overlay-3
	&::after
		bottom: unset
		top: r(340px)
	.scollbar-wrap
		@screen lg
			height: r(230px)
	.container
		@apply relative z-30
	.block-wrap
		@apply relative z-30 bg-blue-primary text-white font-normal
		padding: r(20px)
		*
			@apply text-white font-normal
		@screen lg
			padding: r(30px)
	.headline
		@apply font-normal text-white mb-4
		@screen lg
			@apply mb-5
		*
			@apply font-normal text-white
	ul
		+list
		em
			@apply font-light
		em,span,p
			@apply text-white font-normal
		span,p
			+fz(15px,18px)
	table
		tr
			td
				@apply text-white font-normal
				+fz(15px,18px)
				&:first-child
					@apply pl-6
				*
					@apply text-white font-normal
					+fz(15px,18px)
	.single-swiper
		@apply pt-8
		@screen lg
			@apply pt-12
		.img
			@apply rounded-[20px] overflow-hidden
			a
				@media screen and ( max-width: 1024px)
					+img-ratio(450/750)
				@screen lg
					+img-ratio(550/1320)