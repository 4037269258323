.map-wrapper 
	@apply h-full
	a
		display: block
		height: 100%
		@apply overflow-hidden
		text-align: center
		width: 100%
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 576px)
			height: 300px
		iframe
			@apply inline-block w-full h-full