.menu-detail
	@apply relative lg:pb-20 pb-10
	.swiper-nav-wrap
		.nav-prev,.nav-next
			@media (max-width: 1023.98px)
				@apply left-0 right-0
		a
			+img-ratio(550/1320)
	.single-swiper 
		@apply mb-8
		@screen lg
			@apply mb-40
		.swiper-slide
			img
				border-radius: r(20px)
	.stats
		@apply pb-5
		ul
			@apply center-item
		li
			
			@apply col-hor text-center
			padding: 0 r(30px)
			@screen lg
				padding: 0 r(40px)
			@screen xl
				padding: 0 r(60px)
			&:not(:last-child)
				@apply border-r border-r-grey-100
		.icon
			@apply center-item
			height: r(80px)
			width: 100%
			img
				width: auto
				height: 100%
				object-fit: contain
	table
		font-size: r(16px)
		thead, tbody, td, tr, th
			@apply border border-[#E7E7E7] p-2
		thead
			@apply bg-blue-primary font-bold text-white
		tr
			th:not(:first-child), td:not(:first-child)
				@apply text-center
	.notes
		font-size: r(18px)
		@apply py-7 text-primary
	h3.headline
		font-size: r(24px)
		@apply text-blue-primary font-bold
	.desc
		@apply pt-3 text-center w-full
	.desc-info
		@apply text-green-primary text-center font-bold w-full
	.more-info
		@apply bg-[#E6EFF7] rounded-lg p-4 mt-7 lg:mb-20 mb-5
		.desc
			@apply text-left
	.format-content
		font-size: 0.9375rem
		p
			font-size: 0.9375rem
			margin-bottom: 1.04rem
		h3,h4,h2
			font-size: 1.25rem 
			margin-bottom: 1.04rem
			@apply font-Judson text-blue-primary font-bold 
		ul
			margin-bottom: 1.04rem
			gap: r(8px)
			@apply list-disc pl-5 flex flex-col
			li
				font-size: r(18px)
				color: #1A1A1A
				line-height: 1.4
				&::marker
					font-size: 14px
					color: #1A1A1A
		fieldset
			border: 0
			padding: 1.25rem
			margin: 0
			min-width: 0
			font-size: 0.9375rem
			@apply bg-[#E6EFF7]
			legend
				font-size: 1.25rem
				display: table
				float: left
				margin: 0
				padding: 0
				width: 100%
				margin-bottom: 0.84rem
				&+*
					clear: both
				@apply font-Judson text-blue-primary font-bold 
		.table-responsive-custom 
			margin-bottom: 1.04rem
			@apply border border-[#E7E7E7] rounded-[10px] overflow-hidden
			@media (max-width: 1023.98px)
				@apply overflow-auto py-1 pt-0
			@media (max-width: 1023.98px)
				table
					@apply w-[1300px]
		table
			margin: -1px
			// @apply table-fixed
			tr
				&:nth-child(1)
					th
						@apply w-[1%]
						&:nth-child(1)
							@apply w-[15%]
						&:nth-child(2)
							@apply w-[10%]
						&:nth-child(3)
							@apply whitespace-nowrap
						&:nth-child(4)
							@apply w-[18%]
				&:nth-child(2)
					th
						&:nth-child(1)
							@apply whitespace-nowrap text-center
						&:nth-child(2)
							@apply w-[15%]
						&:nth-child(3)
							@apply w-[10%]
						&:nth-child(4)
							@apply whitespace-nowrap
						&:nth-child(5)
							@apply w-[10%]
							

					
	.five-swiper
		@apply -mx-5 lg:-mx-2
		.swiper
			@apply py-5 px-5 lg:px-2
		.swiper-slide
			&.active
				.menu-item
					@apply bg-blue-primary
					.txt
						h5
							@apply text-white
						h3
							@apply text-white
			.menu-item
				@apply rounded-[16px] overflow-hidden p-2 transition-all duration-300 bg-white
				box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05)
				.img
					@apply overflow-hidden pb-4
					@apply rounded-lg
					+img-ratio(142/230)
				.txt
					padding: r(8px)
					@apply bg-transparent
					h5
						font-size: r(16px)
						line-height: r(22px)
						@apply transition-all duration-300
					h3
						font-weight: 400
						font-size: r(20px)
						line-height: r(28px)
						@apply transition-all duration-300
			&:hover
				.menu-item
					@apply bg-blue-primary
					.txt
						h5
							@apply text-white
						h3
							@apply text-white
.other-menu
	background-color: #E7F6EF80
	.swiper-slide
		.menu-item
			.txt
				@apply bg-white
			.headline
				a
					@apply text-blue-primary
			&:hover
				.headline
					a
						@apply text-blue-primary
body:not(:-moz-handler-blocked)
	fieldset
		display: table-cell