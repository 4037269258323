.about-facility
	.tab-nav
		padding: r(14px) 0 r(40px)
.about-menu-list
	@apply bg-blue-E6EFF7 relative overflow-hidden
	+overlay
	.grid-center
		margin-top: r(40px)
.gallery-document 
	.grid-center
		margin-top: r(40px)
	.document-item
		padding: 0
		&:not(:last-child)
			@apply mb-4
		.icon
			min-width: 42px
			width: 42px
.video-item 
	@apply relative
	.img
		@apply relative rounded-[20px] overflow-hidden
		&::before
			content: ''
			@apply block absolute-center pointer-events-none z-20  transition 
			width: r(90px)
			height: r(60px)
			background: url('../img/icon/play.svg')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			@media screen and ( max-width: 1024px)
				width: r(60px)
				height: r(40px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			opacity: 0.5
			background: linear-gradient(180deg, rgba(0, 92, 171, 0.00) 0%, #0062AF 100%)
		a
			+img-ratio(312/416)
	.headline
		@apply absolute-x bottom-0 pb-4 z-30 pointer-events-none
	
.facility-item 
	.img
		@apply relative
		@apply overflow-hidden rounded-[20px]
		a
			+img-ratio(312/416)
	.headline
		padding: r(16px) 0 r(24px)
	ul
		+list
		table
			@apply hidden
.support-policy
	@apply relative bg-blue-E6EFF7
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full 
		height: 85%
		background: url('../img/overlay/overlay-2.svg')
		background-repeat: no-repeat
		background-size: contain
		background-position: bottom center
	.container
		@apply z-30 relative
	.row
		@apply mb-0
		&:nth-child(odd)
			.txt
				@screen lg
					padding-left: 8%
		&:nth-child(even)
			@apply flex-row-reverse
	.img
		@apply rounded-[20px] overflow-hidden
		a
			+img-ratio(480/640)
	.txt
		@apply col-hor h-full
	.headline
		@apply text-blue-primary mb-16
		*
			@apply text-blue-primary
.link-faq
	background: lightgray -1.631px -142.293px / 112.685% 131.947% no-repeat
	@media screen and ( max-width: 768px)
		@apply pb-10
	.img
		@apply flex items-end justify-center
		height: r(470px)
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
	.txt 
		@apply col-hor h-full pb-4
	.block-title
		@apply mb-4
	.desc
		@apply mb-3
		@screen lg
			@apply mb-6
		p
			&:not(:last-child)
				@apply mb-1
				@screen lg
					@apply mb-2