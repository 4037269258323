.facility-list
	@apply relative
	+overlay-3
	&::after
		bottom: unset
		top: r(340px)
	.container
		@apply relative z-40
	.tab-wrapper
		@apply mt-40
	.row
		@apply border border-green-primary rounded-[20px] bg-white
		box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05)
		margin: 0 
		padding: r(20px)
		@screen sm
			padding: r(30px)
		@screen lg
			padding: r(40px)
		&:not(:last-child)
			margin: 0 0 30px 0
		.col
			margin: 0 
			padding: 0
		&:nth-child(even)
			@apply flex-row-reverse
			.txt
				@screen md
					@apply pl-0 pr-6
				@screen xl
					padding-right: r(60px)
	.zone-desc
		+line(4)
		p + p
			@apply hidden
	.img
		@apply overflow-hidden rounded-[20px]  my-auto
		@screen lg
			@apply rounded-[40px]
		a
			@apply w-full block
			+img-ratio(366/488)
	.txt
		@media screen and ( max-width: 768px)
			@apply pt-6
		@screen md
			@apply pl-6
		@screen xl
			padding-left: r(60px)
		ul
			@apply mt-4
			+list
			@apply text-grey-500
			*
				@apply text-grey-500
			li
				span,p,strong
					@apply text-grey-500
			table
				@apply hidden
	.headline
		@apply mb-16 font-normal
		*
			@apply font-normal
	.desc
		@apply text-grey-500
		*
			@apply text-grey-500
	.wrap-left
		@apply mt-4