.top-menu
	z-index: 1
	@apply bg-[#E6EFF7] overflow-hidden
	+overlay-3
	&::after
		bottom: 0
		top: auto
		z-index: 2
		width: 300px
		height: 400px
	.img
		a
			+img-ratio(424/185)
	.row
		.col
			@apply md:mb-0
	.img
		+img-ratio(657/1168)
	h3
		font-size: r(18px)
		@apply mb-3
	.format-content
		p
			font-size: 0.9375rem
			