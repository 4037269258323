.block-title
	@apply  font-bold text-blue-primary  mb-22 leading-tight
	+fz(28px,36px)
	*
		@apply  font-bold  text-blue-primary leading-tight
		+fz(28px,36px)
	&.t-40
		+fz(32px,40px)
		*
			+fz(32px,40px)
	&.t-32
		+fz(24px,32px)
		*
			+fz(24px,32px)
.headline
	@apply text-grey-700 font-bold font-Judson
	+fz(20px,24px)
	*
		@apply  text-grey-700  font-bold font-Judson
		+fz(20px,24px)
	&.t-22
		+fz(19px,22)
		*
			+fz(19px,22)
	&.t-20
		+fz(17px,20px)
		*
			+fz(17px,20px)
	&.t-18
		+fz(15px,18px)
		*
			+fz(15px,18px)
	&.white
		@apply text-white
	&.blue
		@apply text-blue-primary
		*
			@apply text-blue-primary

.eyebrow
.sub-title
.block-title,.zone-title,.headline,.eyebrow,.sub-title
	@apply transition font-Judson
	*
		@apply transition font-Judson
.zone-time
	@apply start-item items-center
time
	@apply text-grey-500 font-normal font-Bai
	+fz(14px,16px)
	*
		@apply text-grey-500 font-normal font-Bai
		+fz(14px,16px)
.zone-type
	@apply text-green-primary font-bold start-item items-center  relative
	padding-left: r(20px)
	+fz(14px,16px)
	*
		@apply text-green-primary font-bold
		+fz(14px,16px)
	&::before
		content: ''
		@apply inline-block absolute-y left-[10px]  pointer-events-none z-10  transition bg-grey-500
		height: 2px
		width: 2px 