.home-learn-more
	.item
		@screen lg
			&:hover
				.txt
					@apply bottom-0
				.grid-center
					@apply opacity-100
	.img
		@apply rounded-[20px] overflow-hidden relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-20  transition w-full h-full bg-gradient opacity-50
			@media screen and ( max-width: 576px)
				background: linear-gradient(180deg, rgba(0, 92, 171, .2) 0%, #0062AF 100%)
		a
			@apply z-10
			+img-ratio(312/416)
	.txt
		@apply absolute-x  z-30 pointer-events-none text-center w-full transition bottom-0
		padding: r(20px)
		@screen lg
			padding: 0 r(30px)
			bottom: -60px
	.grid-center
		margin: r(20px) 0
		@apply opacity-0 pointer-events-none transition
		@media screen and ( max-width: 1024px)
			@apply hidden