//Type 1 first child icon
.modulepager,.pagination-wrap
	@apply flex items-center justify-center pt-8 pb-0
	@media screen and ( max-width:1024px)
	padding-bottom: 30px
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&:last-child()
			&.active
				a,span
					@apply bg-blue-primary text-white border-blue-primary
			&:hover
				a,span
					@apply underline
			span,a
				width: 32px
				height: 32px
				text-decoration: none
				@apply text-blue-primary font-Judson center-item transition rounded-full border border-green-primary
				+fz(20px,24px)
	.next,.prev
		@apply hidden