header
	height: 100px
	@apply fixed top-0 left-0 right-0  bg-white w-full font-Judson
	z-index: 121
	box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05)
	*
		@apply font-Judson
	@media screen and ( max-width: 1200px)
		height: 60px
	.container
		@apply relative end-item h-full items-center
.nav-brand
	@apply z-20  absolute-y left-[15px] 
	@media screen and ( max-width: 1200px)
		a
			height: 50px
	a
		display: flex
		height: 82px
		width: auto
		@media screen and ( max-width: 1200px)
			height: 50px
		img,svg
			width: auto
			height: 100%
			object-fit: contain
.nav-primary-menu
	@media screen and ( max-width: 1200px)
		@apply hidden pt-4
		margin: 0 r(20px)
	@screen xl
		@apply h-full 
	.Module,.ModuleContent
		@apply h-full items-center w-full
	.nav
		@apply gap-item
		@media screen and ( min-width:1200px )
			@apply h-full
		@media screen and ( max-width: 1200px)
			@apply col-hor w-full
	.nav-link
		@apply relative
		@media screen and ( max-width: 1200px)
			@apply w-full py-3
		@screen xl
			@apply center-item h-full
			margin: 0 r(20px) 
			&::after
				content: ''
				@apply block absolute-x  pointer-events-none  bottom-0 z-10  transition w-0 h-[2px] bg-green-primary
			&:hover
				&::after
					@apply w-full
		@media screen and ( min-width: 1360px)
			margin: 0 r(25px) 
		&:last-child
			a
				@apply pr-0
		&:hover
			@screen lg
				>a
					@apply text-green-primary
				.title
					>a
						@apply text-green-primary
		&.active
			@screen lg
				&::after
					@apply w-full
			>a
				@media screen and ( min-width:1200px )
					@apply text-green-primary
				@media screen and ( max-width: 1200px)
					@apply text-green-primary
			.title
				>a
					@apply text-green-primary
				em
					@apply text-green-primary
		a
			@apply start-item leading-none   relative transition  w-full
			font-size: clamp(18px,r(20px),20px)
			padding: 0
			@media screen and ( max-width: 1200px)
				@apply text-white font-semibold  text-grey-700
			@media screen and ( min-width:1200px )
				@apply font-medium   justify-center
				+fz(16px,18px)
			@media screen and ( min-width: 1280px)
				+fz(18px,20px)
	.drop-down
		@apply relative
		@media screen and ( max-width: 1200px)
			@apply flex flex-col
		&.is-open
			.title
				em
					&:before
						transform: scaleY(-1)
		.nav-link-sub
			@apply start-item 
			a
				@apply normal-case font-medium  py-2  px-0  font-Bai  text-grey-700 
				justify-content: flex-start
				+fz(15px,18px)
				@media screen and ( max-width: 1200px)
					@apply p-4 py-2
				&:hover
					@apply underline
			&.active
				a
					@apply underline
		.title
			@apply relative
			@media screen and ( max-width: 1200px)
				@apply text-white font-semibold  text-grey-700 gap-item w-full 
			@media screen and ( min-width: 1200px)
				@apply font-medium   center-item p-0
				font-size: clamp(14px,r(16px),16px)
			a
				@apply  relative

			em
				margin-left: r(10px)
				@apply  leading-none center-item transition pointer-events-none
				font-size: 16px
				height: 12px
				&:before
					@apply transition
				@media screen and ( max-width: 1200px)
					width: 40px
					height: 30px
					font-size: 20px
					@apply  absolute right-0 top-50 translate-y-50 text-grey-700
		@media screen and ( min-width: 1200px)
			&:hover
				.nav-sub
					@apply opacity-100 pointer-events-auto transform-none
		.nav-sub
			@media screen and ( max-width: 1200px)
				@apply hidden  pt-3 pb-0
			@media screen and ( min-width: 1200px)
				padding: r(10px)  r(25px)
				@apply absolute top-full left-0 w-max z-20 opacity-0 pointer-events-none bg-green-CDEDDD border-none
				transform: translateY(25%)
				transition: .3s all  ease-in-out
.site-hot-line
	@apply h-full
	a
		@apply start-item items-center h-full
	em
		@apply leading-none mr-[10px]
		font-size: 18px

	span
		@apply text-white font-extrabold leading-normal uppercase
		font-size: 12px
	@media screen and ( max-width: 1200px)
		@apply hidden
	.Module,.ModuleContent
		@apply h-full
.menu-toggle
	@apply  center-item items-center h-full mt-1
	@screen xl
		@apply hidden
.mobile-nav-wrap
	@apply fixed top-[60px] left-0 w-full h-full bg-white z-[122]
	+boxshadow
	width: 320px
	height: calc(100vh - 60px)
	transform: translateX(-100%)
	transition: transform .8s cubic-bezier(.165,.84,.44,1)
	@media screen and ( min-width:1200px )
		@apply hidden 
	&.is-open
		transform: none