.language-wrapper
	@apply p-0 relative overflow-hidden flex  mx-16
	z-index: 121
	@screen xl
		@apply mx-32
	ul
		@apply start-item
	li
		@apply pointer-events-none
		&:not(:last-child)
			a
				border-right: 1px solid #ccc
		&.active
			a
				@apply text-blue-primary
	a
		@apply text-grey-700 font-normal uppercase leading-none center-item
		padding: 0 8px
		+fz(20px,24px)
		@screen lg
			+fz(18px,20px)
			&:hover
				@apply underline