.document-list
	@apply relative
	.container
		@apply relative z-40
	&::after
		content: ''
		@apply block absolute pointer-events-none right-0 bottom-0 z-10 transition w-full h-full
		max-width: r(705px)
		background: rgba(#E7F6EF, .5)
	.document-item
		border-radius: 20px
		box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05)
		@apply bg-white
		&:hover
			.title
				@apply text-blue-primary
				*
					@apply text-blue-primary
.document-item
	@apply px-8 py-6 h-full start-item
	align-items: flex-start
	border-radius: 60px 0
	@media screen and ( max-width: 1024px)
		border-radius: 30px 0 
		@apply p-6
	time
		@apply mb-1 text-grey-500 leading-none
		+fz(14px,16px)
	.title
		@apply  font-medium  transition text-grey-500 transition leading-tight
		+fz(15px,18px)
		*
			@apply  font-medium  transition text-grey-500 transition
			+fz(15px,18px)
	&:hover
		.title
			@apply text-green-primary underline
			*
				@apply text-green-primary underline
	.icon
		@apply center-item  mr-20 pt-1
		width: 64px
		min-width: 64px
		img
			width: auto
			height: 100%
			object-fit: contain
	.btn-view-more
		@apply mt-2
.gallery-list
	.row
		@media screen and ( max-width: 576px)
			margin: 0 -5px -10px -5px
			.col
				padding: 0 5px
				margin-bottom: 10px
	
	.col
		@screen lg
			&:nth-child(3),&:nth-child(4),&:nth-child(5)
				width: 33.33%
			
.video-figure 
	@apply relative
	.img
		@apply relative rounded-[20px] overflow-hidden
		&::before
			content: ''
			@apply block absolute-center pointer-events-none z-20  transition 
			width: r(90px)
			height: r(60px)
			background: url('../img/icon/play.svg')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			@media screen and ( max-width: 1024px)
				width: r(60px)
				height: r(40px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			opacity: 0.5
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)
		a
			+img-ratio(271/417)
	.headline
		@apply absolute-x bottom-0 pb-4 z-30 pointer-events-none