.swiper-grid-section
	.grid-swiper
		height: 300px
	.swiper
		width: 100%
		height: 100%
		margin-left: auto
		margin-right: auto
	.swiper-slide
		height: calc((100% - 30px) / 2) !important
	.item
		@apply py-10 bg-primary h-full text-center center-item

.pad-10
	padding: r(50px) 0
	@screen xl
		padding: r(90px) 0
.pad-t-10
	padding-top: r(50px)
	@screen xl
		padding-top: r(90px) 
.pad-b-10
	padding-bottom: r(50px)
	@screen xl
		padding-bottom: r(90px) 
.pad-8
	padding: r(50px) 0
	@screen xl
		padding: r(70px) 0 
.pad-6-8
	padding: r(50px) 0
	@screen xl
		padding: r(60px) 0  r(80px)
.pad-t-8
	padding-top: r(50px)
	@screen xl
		padding-top: r(80px) 
.pad-b-8
	padding-bottom: r(50px)
	@screen xl
		padding-bottom: r(80px) 
.pad-6
	padding: r(50px) 0
	@screen xl
		padding: r(60px) 0 
.pad-t-6
	padding-top: r(50px)
	@screen xl
		padding-top: r(60px) 
.pad-b-6
	padding-bottom: r(50px)
	@screen xl
		padding-bottom: r(60px) 
.pad-t-4
	padding-top: r(40px)
.pad-b-4
	padding-bottom: r(40px)
.social-network
	.ModuleContent
		@apply start-item
		a
			@apply center-item rounded-full  rounded-full leading-none relative z-20
			width: 36px
			height: 36px
			&:not(:last-child)
				@apply mr-[10px]
			em
				@apply text-green-primary leading-none
				font-size: 16px