.btn
	span,em
		transition: .4s all  ease-in-out
	&.btn-primary
		min-height: 40px
		height: r(48px)
		padding: r(8px) r(24px)
		@apply border-2 border-green-primary rounded-full overflow-hidden
		&::after
			content: ''
			@apply block absolute pointer-events-none left-[-1px] bottom-0 z-10  transition w-0 h-full bg-green-primary
		span,em
			@apply text-blue-primary leading-none relative z-30
		span
			font-size: 16px
			@apply font-Judson font-bold uppercase 
		em
			@apply font-light ml-[8px]
			font-size: 10px
		@media screen and ( max-width: 1200px)
			height: 40px
		&:hover
			&::after
				@apply w-full
			span,em
				@apply text-white
		&.white
			@apply border-white
			span,em
				@apply text-white
			&:hover
				@apply border-green-primary
				&::after
					@apply bg-green-primary
	&.btn-secondary
		@apply pr-1
		span,em
			@apply text-blue-primary leading-none relative z-30
		span
			font-size: 16px
			@apply font-Judson font-bold 
		em
			@apply font-light ml-[12px]
			font-size: 18px
		@media screen and ( max-width: 1200px)
			height: 40px
		&:hover
			em,span
				@apply text-green-primary
			em
				transform: translateX(4px)
	&.btn-tertiary
		span,em
	&.btn-view-more
		span,em
			@apply transition leading-none  text-blue-primary 
		span
			font-size: 14px
		em
			font-size: 16px
			@apply inline-block mr-2
		img
			@apply mr-1 inline-block

.nav-prev,.nav-next
	@apply z-10 transition absolute top-1/2 translate-y-50 cursor-pointer center-item  rounded-full transition bg-blue-E6EFF7 rounded-full overflow-hidden
	width: r(60px)
	height: r(60px)
	line-height: r(60px)
	&::before
		content: ''
		@apply block absolute pointer-events-none bottom-0 z-10  transition w-0 h-full bg-green-primary
	@media screen and ( max-width:  1200px)
		@apply relative mx-6
		transform: none
		width: 40px
		height: 40px
		line-height: 40px
	em
		@apply inline-block transition leading-none text-blue-primary relative z-30
		font-size: r(32px)
		height: r(32px)
		line-height: r(32px)
		@media screen and ( max-width: 1200px)
			font-size: 20px
			height: 20px
			line-height: 20px
	&:hover
		em
			@apply text-white
		&::before,&::after
			@apply w-full

.swiper-nav-wrap
	@media screen and ( max-width: 1200px)
		@apply center-item pt-8
	&.normal
		padding: 0
		margin: 0
		.nav-next,.nav-prev
			position: relative !important
			transform: none !important
		.nav-prev
			margin-right: 16px


.nav-next
	right: -90px
	&::before
		@apply left-0
	@media screen and ( min-width: 1024px) and ( max-width: 1440px)
		right: -50px
.nav-prev
	left: -90px
	&::before
		@apply right-0
	@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
		left: -50px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
