.desc
	@apply text-grey-700 font-normal leading-normal
	+fz(15px,18px)
	*
		@apply text-grey-700 font-normal leading-normal
		+fz(15px,18px)
	&.t-24
		+fz(20px,24px)
		*
			+fz(20px,24px)
	&.t-22
		+fz(18px,22px)
		*
			+fz(18px,22px)
	&.t-20
		+fz(16px,20px)
		*
			+fz(16px,20px)
	&.t-16
		+fz(13px,16px)
		*
			+fz(13px,16px)
	&.t-14
		+fz(13px,14px)
		*
			+fz(13px,14px)
.zone-desc
.desc,.zone-desc
	@apply transition font-Bai
	*
		@apply transition font-Bai