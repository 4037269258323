@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
  color: #333333;
  font-weight: 400;
  font-size: clamp(14px, 3.6vw, 16px);
  font-family: 'Bai Jamjuree', sans-serif; }

@screen sm {
  html, body {
    font-size: 2.4vw; } }

@screen md {
  html, body {
    font-size: 1.5vw; } }

@screen lg {
  html, body {
    font-size: 1.1vw; } }

@screen xl {
  html, body {
    font-size: 1vw; } }

*:focus {
  outline: none; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit; }

p {
  margin-bottom: 10px;
  font-weight: 400;
  color: inherit;
  font-size: 1rem; }

figure p {
  font-weight: 300;
  font-size: 1rem; }

time {
  @apply font-normal  inline-block {}  color: #999999; }

html {
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
  color: #333333;
  text-align: left;
  background-color: #ffffff; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

.frm-btn-reset {
  display: none !important; }

.col-12 {
  @apply w-full {}  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0 15px; }

.col-lg-8 {
  padding: 0 15px; }

@screen lg {
  .col-lg-8 {
    width: 66.666667%; } }

.col-lg-4 {
  padding: 0 15px; }

@screen lg {
  .col-lg-4 {
    width: 33.333333%; } }

.col-xs-12 {
  padding: 0 15px;
  width: 100%; }

.col-lg-3 {
  padding: 0 15px; }

@screen lg {
  .col-lg-3 {
    width: 25%; } }

.col-lg-9 {
  padding: 0 15px; }

@screen lg {
  .col-lg-9 {
    width: 75%; } }

.col-lg-6 {
  padding: 0 15px; }

@screen lg {
  .col-lg-6 {
    width: 50%; } }

.btn span, .btn em {
  transition: .4s all  ease-in-out; }

.btn.btn-primary {
  min-height: 40px;
  height: 2.5rem;
  padding: 0.41667rem 1.25rem;
  @apply border-2 border-green-primary rounded-full overflow-hidden {} }
  .btn.btn-primary::after {
    content: '';
    @apply block absolute pointer-events-none left-[-1px] bottom-0 z-10  transition w-0 h-full bg-green-primary {} }
  .btn.btn-primary span, .btn.btn-primary em {
    @apply text-blue-primary leading-none relative z-30 {} }
  .btn.btn-primary span {
    font-size: 16px;
    @apply font-Judson font-bold uppercase {} }
  .btn.btn-primary em {
    @apply font-light ml-[8px] {}    font-size: 10px; }
  @media screen and (max-width: 1200px) {
    .btn.btn-primary {
      height: 40px; } }
  .btn.btn-primary:hover::after {
    @apply w-full {} }
  .btn.btn-primary:hover span, .btn.btn-primary:hover em {
    @apply text-white {} }
  .btn.btn-primary.white {
    @apply border-white {} }
    .btn.btn-primary.white span, .btn.btn-primary.white em {
      @apply text-white {} }
    .btn.btn-primary.white:hover {
      @apply border-green-primary {} }
      .btn.btn-primary.white:hover::after {
        @apply bg-green-primary {} }

.btn.btn-secondary {
  @apply pr-1 {} }
  .btn.btn-secondary span, .btn.btn-secondary em {
    @apply text-blue-primary leading-none relative z-30 {} }
  .btn.btn-secondary span {
    font-size: 16px;
    @apply font-Judson font-bold {} }
  .btn.btn-secondary em {
    @apply font-light ml-[12px] {}    font-size: 18px; }
  @media screen and (max-width: 1200px) {
    .btn.btn-secondary {
      height: 40px; } }
  .btn.btn-secondary:hover em, .btn.btn-secondary:hover span {
    @apply text-green-primary {} }
  .btn.btn-secondary:hover em {
    transform: translateX(4px); }

.btn.btn-view-more span, .btn.btn-view-more em {
  @apply transition leading-none  text-blue-primary {} }

.btn.btn-view-more span {
  font-size: 14px; }

.btn.btn-view-more em {
  font-size: 16px;
  @apply inline-block mr-2 {} }

.btn.btn-view-more img {
  @apply mr-1 inline-block {} }

.nav-prev, .nav-next {
  @apply z-10 transition absolute top-1/2 translate-y-50 cursor-pointer center-item  rounded-full transition bg-blue-E6EFF7 rounded-full overflow-hidden {}  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem; }
  .nav-prev::before, .nav-next::before {
    content: '';
    @apply block absolute pointer-events-none bottom-0 z-10  transition w-0 h-full bg-green-primary {} }
  @media screen and (max-width: 1200px) {
    .nav-prev, .nav-next {
      @apply relative mx-6 {}      transform: none;
      width: 40px;
      height: 40px;
      line-height: 40px; } }
  .nav-prev em, .nav-next em {
    @apply inline-block transition leading-none text-blue-primary relative z-30 {}    font-size: 1.66667rem;
    height: 1.66667rem;
    line-height: 1.66667rem; }
    @media screen and (max-width: 1200px) {
      .nav-prev em, .nav-next em {
        font-size: 20px;
        height: 20px;
        line-height: 20px; } }
  .nav-prev:hover em, .nav-next:hover em {
    @apply text-white {} }
  .nav-prev:hover::before, .nav-prev:hover::after, .nav-next:hover::before, .nav-next:hover::after {
    @apply w-full {} }

@media screen and (max-width: 1200px) {
  .swiper-nav-wrap {
    @apply center-item pt-8 {} } }

.swiper-nav-wrap.normal {
  padding: 0;
  margin: 0; }
  .swiper-nav-wrap.normal .nav-next, .swiper-nav-wrap.normal .nav-prev {
    position: relative !important;
    transform: none !important; }
  .swiper-nav-wrap.normal .nav-prev {
    margin-right: 16px; }

.nav-next {
  right: -90px; }
  .nav-next::before {
    @apply left-0 {} }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .nav-next {
      right: -50px; } }

.nav-prev {
  left: -90px; }
  .nav-prev::before {
    @apply right-0 {} }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .nav-prev {
      left: -50px; } }

.wrap-gap, .wrap-center, .wrap-left, .wrap-right, .ajax-center-wrap {
  @apply flex items-center {} }
  .wrap-gap a:not(:last-child), .wrap-center a:not(:last-child), .wrap-left a:not(:last-child), .wrap-right a:not(:last-child), .ajax-center-wrap a:not(:last-child) {
    @apply mr-2 {} }

.wrap-center, .ajax-center-wrap {
  @apply justify-center {} }

.wrap-left {
  @apply justify-start {} }

.wrap-right {
  @apply justify-end {} }

.wrap-gap {
  @apply justify-between {} }

.row {
  @apply flex flex-wrap {}  justify-content: flex-start; }

.col {
  flex: 0 0 auto;
  @apply flex flex-col {}  padding: 0 15px; }
  .col .col {
    margin-bottom: 0; }

.row {
  margin: 0 -15px -30px -15px; }
  .row > .col {
    margin-bottom: 30px; }

.col-match-height {
  @apply flex w-full flex-col h-full {} }

.container, .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.block-title {
  @apply font-bold text-blue-primary  mb-22 leading-tight {}  font-size: clamp(28px, 1.875rem, 36px); }
  .block-title * {
    @apply font-bold  text-blue-primary leading-tight {}    font-size: clamp(28px, 1.875rem, 36px); }
  .block-title.t-40 {
    font-size: clamp(32px, 2.08333rem, 40px); }
    .block-title.t-40 * {
      font-size: clamp(32px, 2.08333rem, 40px); }
  .block-title.t-32 {
    font-size: clamp(24px, 1.66667rem, 32px); }
    .block-title.t-32 * {
      font-size: clamp(24px, 1.66667rem, 32px); }

.headline {
  @apply text-grey-700 font-bold font-Judson {}  font-size: clamp(20px, 1.25rem, 24px); }
  .headline * {
    @apply text-grey-700  font-bold font-Judson {}    font-size: clamp(20px, 1.25rem, 24px); }
  .headline.t-22 {
    font-size: clamp(19px, 1.14583rem/px, 22); }
    .headline.t-22 * {
      font-size: clamp(19px, 1.14583rem/px, 22); }
  .headline.t-20 {
    font-size: clamp(17px, 1.04167rem, 20px); }
    .headline.t-20 * {
      font-size: clamp(17px, 1.04167rem, 20px); }
  .headline.t-18 {
    font-size: clamp(15px, 0.9375rem, 18px); }
    .headline.t-18 * {
      font-size: clamp(15px, 0.9375rem, 18px); }
  .headline.white {
    @apply text-white {} }
  .headline.blue {
    @apply text-blue-primary {} }
    .headline.blue * {
      @apply text-blue-primary {} }

.block-title, .zone-title, .headline, .eyebrow, .sub-title {
  @apply transition font-Judson {} }
  .block-title *, .zone-title *, .headline *, .eyebrow *, .sub-title * {
    @apply transition font-Judson {} }

.zone-time {
  @apply start-item items-center {} }

time {
  @apply text-grey-500 font-normal font-Bai {}  font-size: clamp(14px, 0.83333rem, 16px); }
  time * {
    @apply text-grey-500 font-normal font-Bai {}    font-size: clamp(14px, 0.83333rem, 16px); }

.zone-type {
  @apply text-green-primary font-bold start-item items-center  relative {}  padding-left: 1.04167rem;
  font-size: clamp(14px, 0.83333rem, 16px); }
  .zone-type * {
    @apply text-green-primary font-bold {}    font-size: clamp(14px, 0.83333rem, 16px); }
  .zone-type::before {
    content: '';
    @apply inline-block absolute-y left-[10px]  pointer-events-none z-10  transition bg-grey-500 {}    height: 2px;
    width: 2px; }

.desc {
  @apply text-grey-700 font-normal leading-normal {}  font-size: clamp(15px, 0.9375rem, 18px); }
  .desc * {
    @apply text-grey-700 font-normal leading-normal {}    font-size: clamp(15px, 0.9375rem, 18px); }
  .desc.t-24 {
    font-size: clamp(20px, 1.25rem, 24px); }
    .desc.t-24 * {
      font-size: clamp(20px, 1.25rem, 24px); }
  .desc.t-22 {
    font-size: clamp(18px, 1.14583rem, 22px); }
    .desc.t-22 * {
      font-size: clamp(18px, 1.14583rem, 22px); }
  .desc.t-20 {
    font-size: clamp(16px, 1.04167rem, 20px); }
    .desc.t-20 * {
      font-size: clamp(16px, 1.04167rem, 20px); }
  .desc.t-16 {
    font-size: clamp(13px, 0.83333rem, 16px); }
    .desc.t-16 * {
      font-size: clamp(13px, 0.83333rem, 16px); }
  .desc.t-14 {
    font-size: clamp(13px, 0.72917rem, 14px); }
    .desc.t-14 * {
      font-size: clamp(13px, 0.72917rem, 14px); }

.desc, .zone-desc {
  @apply transition font-Bai {} }
  .desc *, .zone-desc * {
    @apply transition font-Bai {} }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.gradient-blue {
  background-image: -webkit-linear-gradient(90deg, #47b5e8 0%, #2b74ce 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase; }

.ripple {
  margin: auto;
  margin-top: 5rem;
  background-color: #666;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  animation: ripple 3s linear infinite; }
  .ripple::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: inherit;
    animation-delay: 1.5s; }
  .ripple::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: inherit;
    animation-delay: 1.5s;
    animation-delay: 3s; }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.8); }
  100% {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0); } }

.shine {
  overflow: hidden;
  position: relative; }
  .shine:before {
    position: absolute;
    top: 0;
    pointer-events: none;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }
  .shine:hover:before {
    -webkit-animation: shine .75s;
    animation: shine .75s; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.circle {
  overflow: hidden;
  position: relative; }
  .circle:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0; }
  .circle:hover:before {
    animation: circle .75s; }

@-webkit-keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

@keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

.zoom-in {
  overflow: hidden;
  transition: .45s all ease-in-out; }
  .zoom-in:hover img {
    transform: scale(1.05); }
  .zoom-in img {
    transform: scale(1);
    transition: .3s all ease-in-out; }

.opacity {
  transition: .3s all ease-in-out; }
  .opacity:hover {
    opacity: .8; }
  .opacity img {
    opacity: 1;
    transition: .3s all ease-in-out; }

@keyframes flash {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

@-webkit-keyframes loadingFade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0; } }

@-moz-keyframes loadingFade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0; } }

@keyframes loadingFade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0; } }

.hamburger--elastic .hamburger-inner {
  top: -1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner:before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner:after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner:before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner:after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:before,
  .hamburger.is-active .hamburger-inner:after {
    background-color: #005CAB; }

.hamburger-box {
  width: 32px;
  height: 23px;
  display: block;
  margin: auto 0;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
    width: 32px;
    height: 3px;
    background-color: #005CAB;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner:before, .hamburger-inner:after {
    content: "";
    display: block; }
  .hamburger-inner:before {
    top: -10px; }
  .hamburger-inner:after {
    bottom: -10px; }

.fa-light, .fa-regular, .fas, .fa-brands, .fa-thin {
  font-style: normal; }

.fa-phone-circle::before {
  content: "\e11b"; }

.fa-user-lock::before {
  content: "\f502"; }

.fa-rss::before {
  content: "\f09e"; }

.fa-circle-info::before {
  content: "\f05a"; }

.fa-file-arrow-down::before {
  content: "\f56d"; }

.fa-wrench::before {
  content: "\f0ad"; }

.fa-wrench::before {
  content: "\f0ad"; }

.fa-cart-shopping::before {
  content: "\f07a"; }

.fa-caret-down::before {
  content: "\f0d7"; }

.fa-caret-up::before {
  content: "\f0d8"; }

.fa-caret-left::before {
  content: "\f0d9"; }

.fa-caret-right::before {
  content: "\f0da"; }

.fa-earth-americas::before {
  content: "\f57d"; }

.fa-magnifying-glass::before {
  content: "\f002"; }

.fa-circle-plus::before {
  content: "\f055"; }

.fa-chevron-left::before {
  content: "\f053"; }

.fa-chevron-right::before {
  content: "\f054"; }

.fa-arrow-right::before {
  content: "\f061"; }

.fa-phone::before {
  content: "\f095"; }

.fa-comment-dots::before {
  content: "\f4ad"; }

.fa-location-dot::before {
  content: "\f3c5"; }

.fa-globe::before {
  content: "\f0ac"; }

.fa-envelope::before {
  content: "\f0e0"; }

.fa-phone-arrow-up-right::before {
  content: "\e224"; }

.fa-phone-rotary::before {
  content: "\f8d3"; }

.fa-arrow-down-to-bracket::before {
  content: "\e094"; }

.fa-arrow-down::before {
  content: "\f063"; }

.fa-chevron-down::before {
  content: "\f078"; }

.fa-minus::before {
  content: "\f068"; }

.fa-plus::before {
  content: "\2b"; }

.fa-xmark::before {
  content: "\f00d"; }

.fa-lock::before {
  content: "\f023"; }

.fa-facebook-f::before {
  content: "\f39e"; }

.fa-google::before {
  content: "\f1a0"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-filter::before {
  content: "\f0b0"; }

.fa-arrow-right-to-arc::before {
  content: "\e4b2"; }

.fa-arrow-up::before {
  content: "\f062"; }

.fa-house::before {
  content: "\f015"; }

.fa-long-arrow-left::before {
  content: "\f177"; }

.fa-long-arrow-right::before {
  content: "\f178"; }

.fa-file-pdf::before {
  content: "\f1c1"; }

.fa-linkedin-in::before {
  content: "\f0e1"; }

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd"; }

.fa-angles-right:before {
  content: "\f101"; }

.fa-angles-left:before {
  content: "\f100"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-paper-plane-top:before {
  content: "\e20a"; }

.fa-money-check-dollar-pen:before {
  content: "\f873"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-people-group:before {
  content: "\e533"; }

.fa-down-to-bracket:before {
  content: "\e4e7"; }

.fa-phone-arrow-up-right:before {
  content: "\e224"; }

.fa-arrow-right-long:before {
  content: "\f178"; }

.fa-timer:before {
  content: "\e29e"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-list:before {
  content: "\f03a"; }

.fa-arrow-down-long:before {
  content: "\f175"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-x:before {
  content: "\58"; }

.fa-bars-filter:before {
  content: "\e0ad"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-arrow-up-long:before {
  content: "\f176"; }

.fa-arrow-up-down:before {
  content: "\f175"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-lock:before {
  content: "\f023"; }

main {
  padding-top: 100px; }
  @media screen and (max-width: 1200px) {
    main {
      padding-top: 60px; } }

.four-swiper, .trinity-swiper, .single-swiper, .double-swiper, .five-swiper {
  @apply relative {} }

.main-menu-toggle {
  @apply hidden {} }
  @media screen and (max-width: 1024px) {
    .main-menu-toggle {
      @apply flex items-center pl-8 {} } }
  @media screen and (max-width: 768px) {
    .main-menu-toggle {
      @apply pl-4 {} } }

.frm-thanks {
  @apply my-16 p-8 text-center {}  margin: 0 auto;
  width: 500px; }
  .frm-thanks h3, .frm-thanks h4, .frm-thanks h5 {
    @apply text-primary font-bold text-primary text-16 mb-3 {} }
  .frm-thanks p, .frm-thanks span {
    @apply text-14 text-grey-700 font-normal {} }
  @media screen and (max-width: 1024px) {
    .frm-thanks {
      width: 320px; } }

.tab-item {
  @apply hidden {} }
  .tab-item.active {
    @apply block {} }

.edit-link {
  width: 30px;
  height: 30px;
  pointer-events: auto; }
  .edit-link .fa-pencil {
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg  viewBox='0 0 18 18'  xmlns='http://www.w3.org/2000/svg'><path d='M6.35473 15.7485L2.27202 11.6658L12.1468 1.79106L16.2295 5.87378L6.35473 15.7485ZM1.89039 12.385L5.63556 16.1302L0.0205078 18L1.89039 12.385ZM17.4852 4.62344L16.7771 5.33159L12.6889 1.24347L13.3971 0.535326C14.1104 -0.178442 15.2672 -0.178442 15.9805 0.535326L17.4852 2.04001C18.1934 2.75572 18.1934 3.90795 17.4852 4.62344Z' fill='#094594'/></svg>"); }

.custom-select {
  width: 100%; }
  .custom-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../img/icon/chevron-down.png") no-repeat calc(100% - 15px), rgba(255, 255, 255, 0.5) !important;
    background-position: 95% 50%; }

@media screen and (max-width: 768px) {
  .table-responsive {
    overflow-x: auto; } }

.table-responsive table {
  @apply w-full {} }
  @media screen and (max-width: 768px) {
    .table-responsive table {
      white-space: nowrap; } }
  .table-responsive table thead tr th {
    @apply bg-primary text-white text-16 font-bold text-left  py-4  px-6 {} }
    @media screen and (min-width: 1024px) {
      .table-responsive table thead tr th:first-child {
        @apply pl-12 {} } }
  .table-responsive table tbody tr:nth-child(even) {
    background: #f5f5f5; }
  .table-responsive table tbody tr td {
    @apply py-4 px-6 {} }
    .table-responsive table tbody tr td:first-child {
      width: 25%; }
    @media screen and (min-width: 1024px) {
      .table-responsive table tbody tr td {
        @apply pl-12 {} } }
    .table-responsive table tbody tr td:last-child {
      width: 25%;
      @apply text-right pr-12 {} }
      .table-responsive table tbody tr td:last-child .btn-view-more span {
        color: #af9a56; }

.scollbar-wrap {
  height: 300px;
  padding-right: 15px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #005CAB #E0E0E0; }
  .scollbar-wrap::-webkit-scrollbar {
    background: #E0E0E0;
    width: 3px; }
  .scollbar-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #E0E0E0;
    background-color: #E0E0E0; }
  .scollbar-wrap::-webkit-scrollbar-thumb {
    background-color: #005CAB; }
  @media screen and (max-width: 1024px) {
    .scollbar-wrap {
      height: auto; } }

.qradio input {
  background-repeat: no-repeat;
  background-position: center;
  appearance: none;
  @apply block {}  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>"); }
  .qradio input:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>"); }

@media screen and (max-width: 1024px) {
  .filter-display-wrapper .row {
    margin: 0 -10px -20px -10px; }
    .filter-display-wrapper .row .col {
      padding: 0 10px;
      margin-bottom: 20px; } }

.filter-display-wrapper .col {
  @apply hidden {} }

.filter-display-wrapper .grid-center {
  @apply w-full {} }
  .filter-display-wrapper .grid-center.disble-btn {
    @apply opacity-0 pointer-events-none {} }

.tab-nav {
  @apply center-item w-full {} }
  .tab-nav ul {
    @apply bg-grey-50 rounded-full center-item w-fit center-item {}    box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05);
    padding: 4px; }
  .tab-nav li {
    padding: 0 10px; }
    .tab-nav li:first-child {
      @apply pl-0 {} }
    .tab-nav li:last-child {
      @apply pr-0 {} }
    .tab-nav li.active a {
      @apply bg-blue-primary text-white {} }
  .tab-nav a {
    @apply text-grey-700 font-normal font-Judson rounded-full overflow-hidden center-item transition {}    padding: 4px 0.78125rem;
    height: 40px;
    font-size: clamp(16px, 1.04167rem, 20px); }

.swiper-grid-section .grid-swiper {
  height: 300px; }

.swiper-grid-section .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto; }

.swiper-grid-section .swiper-slide {
  height: calc((100% - 30px) / 2) !important; }

.swiper-grid-section .item {
  @apply py-10 bg-primary h-full text-center center-item {} }

.pad-10 {
  padding: 2.60417rem 0; }

@screen xl {
  .pad-10 {
    padding: 4.6875rem 0; } }

.pad-t-10 {
  padding-top: 2.60417rem; }

@screen xl {
  .pad-t-10 {
    padding-top: 4.6875rem; } }

.pad-b-10 {
  padding-bottom: 2.60417rem; }

@screen xl {
  .pad-b-10 {
    padding-bottom: 4.6875rem; } }

.pad-8 {
  padding: 2.60417rem 0; }

@screen xl {
  .pad-8 {
    padding: 3.64583rem 0; } }

.pad-6-8 {
  padding: 2.60417rem 0; }

@screen xl {
  .pad-6-8 {
    padding: 3.125rem 0 4.16667rem; } }

.pad-t-8 {
  padding-top: 2.60417rem; }

@screen xl {
  .pad-t-8 {
    padding-top: 4.16667rem; } }

.pad-b-8 {
  padding-bottom: 2.60417rem; }

@screen xl {
  .pad-b-8 {
    padding-bottom: 4.16667rem; } }

.pad-6 {
  padding: 2.60417rem 0; }

@screen xl {
  .pad-6 {
    padding: 3.125rem 0; } }

.pad-t-6 {
  padding-top: 2.60417rem; }

@screen xl {
  .pad-t-6 {
    padding-top: 3.125rem; } }

.pad-b-6 {
  padding-bottom: 2.60417rem; }

@screen xl {
  .pad-b-6 {
    padding-bottom: 3.125rem; } }

.pad-t-4 {
  padding-top: 2.08333rem; }

.pad-b-4 {
  padding-bottom: 2.08333rem; }

.social-network .ModuleContent {
  @apply start-item {} }
  .social-network .ModuleContent a {
    @apply center-item rounded-full  rounded-full leading-none relative z-20 {}    width: 36px;
    height: 36px; }
    .social-network .ModuleContent a:not(:last-child) {
      @apply mr-[10px] {} }
    .social-network .ModuleContent a em {
      @apply text-green-primary leading-none {}      font-size: 16px; }

.back-to-top {
  @apply fixed bottom-[40px] right-[30px] bg-white rounded-full center-item z-50 {}  width: 3.125rem;
  height: 3.125rem; }
  @media screen and (max-width: 1024px) {
    .back-to-top {
      width: 40px;
      height: 40px; } }
  .back-to-top em {
    @apply text-blue-primary {}    font-size: 1.25rem; }
    @media screen and (max-width: 1024px) {
      .back-to-top em {
        font-size: 20px; } }

.footer-top {
  @apply bg-blue-CCDEEE {}  padding: 2.60417rem 0; }

@screen lg {
  .footer-top {
    padding: 2.60417rem 0 4.6875rem 0; } }
  .footer-top .headline {
    @apply text-blue-primary mb-16 {}    font-size: clamp(22px, 1.25rem, 24px); }
    .footer-top .headline * {
      @apply text-blue-primary {}      font-size: clamp(22px, 1.25rem, 24px); }
  .footer-top ul li {
    @apply font-normal text-grey-700  leading-none font-Bai {}    font-size: clamp(14px, 0.9375rem, 18px); }
    .footer-top ul li * {
      @apply font-normal text-grey-700  leading-none font-Bai {}      font-size: clamp(14px, 0.9375rem, 18px); }
    .footer-top ul li:not(:last-child) {
      @apply mb-4 {} }
    .footer-top ul li:hover a {
      @apply underline {} }

.footer-bottom {
  padding: 1.5625rem 0 0 0; }
  .footer-bottom .container {
    @apply col-ver {} }
  .footer-bottom .copyright, .footer-bottom .site-title {
    @apply text-grey-700 font-medium leading-normal py-3 text-center {}    font-size: 14px; }
    @media screen and (max-width: 768px) {
      .footer-bottom .copyright, .footer-bottom .site-title {
        @apply pt-4 leading-normal  text-center {} } }
    .footer-bottom .copyright *, .footer-bottom .site-title * {
      @apply text-grey-700 font-medium leading-normal {}      font-size: 14px; }
  .footer-bottom .site-title {
    @apply pb-0 {} }
  .footer-bottom .wrap-right {
    @apply items-center  col-ver {} }
    @media screen and (max-width: 768px) {
      .footer-bottom .wrap-right {
        @apply col-ver {} } }
  .footer-bottom .policy-nav {
    @apply start-item pt-4 {} }
    .footer-bottom .policy-nav a {
      @apply text-grey-700 font-medium leading-none {}      font-size: 14px; }
      .footer-bottom .policy-nav a * {
        @apply text-grey-700 font-medium leading-none {}        font-size: 14px; }
    .footer-bottom .policy-nav li {
      @apply pr-3 leading-none {} }
      .footer-bottom .policy-nav li:hover a {
        @apply underline {} }
      .footer-bottom .policy-nav li:last-child {
        @apply pr-0 {} }
      .footer-bottom .policy-nav li:not(:first-child) {
        @apply relative {} }
        .footer-bottom .policy-nav li:not(:first-child)::before {
          content: '';
          @apply inline-block absolute bottom-[3px] left-[-8px]  pointer-events-none z-10  transition bg-grey-500 {}          height: 2px;
          width: 2px; }

.logo a {
  @apply start-item {}  height: 80px;
  width: auto; }
  .logo a img {
    width: auto;
    height: 100%;
    object-fit: contain; }

header {
  height: 100px;
  @apply fixed top-0 left-0 right-0  bg-white w-full font-Judson {}  z-index: 121;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05); }
  header * {
    @apply font-Judson {} }
  @media screen and (max-width: 1200px) {
    header {
      height: 60px; } }
  header .container {
    @apply relative end-item h-full items-center {} }

.nav-brand {
  @apply z-20  absolute-y left-[15px] {} }
  @media screen and (max-width: 1200px) {
    .nav-brand a {
      height: 50px; } }
  .nav-brand a {
    display: flex;
    height: 82px;
    width: auto; }
    @media screen and (max-width: 1200px) {
      .nav-brand a {
        height: 50px; } }
    .nav-brand a img, .nav-brand a svg {
      width: auto;
      height: 100%;
      object-fit: contain; }

@media screen and (max-width: 1200px) {
  .nav-primary-menu {
    @apply hidden pt-4 {}    margin: 0 1.04167rem; } }

@screen xl {
  .nav-primary-menu {
    @apply h-full {} } }

.nav-primary-menu .Module, .nav-primary-menu .ModuleContent {
  @apply h-full items-center w-full {} }

.nav-primary-menu .nav {
  @apply gap-item {} }
  @media screen and (min-width: 1200px) {
    .nav-primary-menu .nav {
      @apply h-full {} } }
  @media screen and (max-width: 1200px) {
    .nav-primary-menu .nav {
      @apply col-hor w-full {} } }

.nav-primary-menu .nav-link {
  @apply relative {} }
  @media screen and (max-width: 1200px) {
    .nav-primary-menu .nav-link {
      @apply w-full py-3 {} } }

@screen xl {
  .nav-primary-menu .nav-link {
    @apply center-item h-full {}    margin: 0 1.04167rem; }
    .nav-primary-menu .nav-link::after {
      content: '';
      @apply block absolute-x  pointer-events-none  bottom-0 z-10  transition w-0 h-[2px] bg-green-primary {} }
    .nav-primary-menu .nav-link:hover::after {
      @apply w-full {} } }
  @media screen and (min-width: 1360px) {
    .nav-primary-menu .nav-link {
      margin: 0 1.30208rem; } }
  .nav-primary-menu .nav-link:last-child a {
    @apply pr-0 {} }

@screen lg {
  .nav-primary-menu .nav-link:hover > a {
    @apply text-green-primary {} }
  .nav-primary-menu .nav-link:hover .title > a {
    @apply text-green-primary {} } }

@screen lg {
  .nav-primary-menu .nav-link.active::after {
    @apply w-full {} } }
  @media screen and (min-width: 1200px) {
    .nav-primary-menu .nav-link.active > a {
      @apply text-green-primary {} } }
  @media screen and (max-width: 1200px) {
    .nav-primary-menu .nav-link.active > a {
      @apply text-green-primary {} } }
  .nav-primary-menu .nav-link.active .title > a {
    @apply text-green-primary {} }
  .nav-primary-menu .nav-link.active .title em {
    @apply text-green-primary {} }
  .nav-primary-menu .nav-link a {
    @apply start-item leading-none   relative transition  w-full {}    font-size: clamp(18px, 1.04167rem, 20px);
    padding: 0; }
    @media screen and (max-width: 1200px) {
      .nav-primary-menu .nav-link a {
        @apply text-white font-semibold  text-grey-700 {} } }
    @media screen and (min-width: 1200px) {
      .nav-primary-menu .nav-link a {
        @apply font-medium   justify-center {}        font-size: clamp(16px, 0.9375rem, 18px); } }
    @media screen and (min-width: 1280px) {
      .nav-primary-menu .nav-link a {
        font-size: clamp(18px, 1.04167rem, 20px); } }

.nav-primary-menu .drop-down {
  @apply relative {} }
  @media screen and (max-width: 1200px) {
    .nav-primary-menu .drop-down {
      @apply flex flex-col {} } }
  .nav-primary-menu .drop-down.is-open .title em:before {
    transform: scaleY(-1); }
  .nav-primary-menu .drop-down .nav-link-sub {
    @apply start-item {} }
    .nav-primary-menu .drop-down .nav-link-sub a {
      @apply normal-case font-medium  py-2  px-0  font-Bai  text-grey-700 {}      justify-content: flex-start;
      font-size: clamp(15px, 0.9375rem, 18px); }
      @media screen and (max-width: 1200px) {
        .nav-primary-menu .drop-down .nav-link-sub a {
          @apply p-4 py-2 {} } }
      .nav-primary-menu .drop-down .nav-link-sub a:hover {
        @apply underline {} }
    .nav-primary-menu .drop-down .nav-link-sub.active a {
      @apply underline {} }
  .nav-primary-menu .drop-down .title {
    @apply relative {} }
    @media screen and (max-width: 1200px) {
      .nav-primary-menu .drop-down .title {
        @apply text-white font-semibold  text-grey-700 gap-item w-full {} } }
    @media screen and (min-width: 1200px) {
      .nav-primary-menu .drop-down .title {
        @apply font-medium   center-item p-0 {}        font-size: clamp(14px, 0.83333rem, 16px); } }
    .nav-primary-menu .drop-down .title a {
      @apply relative {} }
    .nav-primary-menu .drop-down .title em {
      margin-left: 0.52083rem;
      @apply leading-none center-item transition pointer-events-none {}      font-size: 16px;
      height: 12px; }
      .nav-primary-menu .drop-down .title em:before {
        @apply transition {} }
      @media screen and (max-width: 1200px) {
        .nav-primary-menu .drop-down .title em {
          width: 40px;
          height: 30px;
          font-size: 20px;
          @apply absolute right-0 top-50 translate-y-50 text-grey-700 {} } }
  @media screen and (min-width: 1200px) {
    .nav-primary-menu .drop-down:hover .nav-sub {
      @apply opacity-100 pointer-events-auto transform-none {} } }
  @media screen and (max-width: 1200px) {
    .nav-primary-menu .drop-down .nav-sub {
      @apply hidden  pt-3 pb-0 {} } }
  @media screen and (min-width: 1200px) {
    .nav-primary-menu .drop-down .nav-sub {
      padding: 0.52083rem 1.30208rem;
      @apply absolute top-full left-0 w-max z-20 opacity-0 pointer-events-none bg-green-CDEDDD border-none {}      transform: translateY(25%);
      transition: .3s all  ease-in-out; } }

.site-hot-line {
  @apply h-full {} }
  .site-hot-line a {
    @apply start-item items-center h-full {} }
  .site-hot-line em {
    @apply leading-none mr-[10px] {}    font-size: 18px; }
  .site-hot-line span {
    @apply text-white font-extrabold leading-normal uppercase {}    font-size: 12px; }
  @media screen and (max-width: 1200px) {
    .site-hot-line {
      @apply hidden {} } }
  .site-hot-line .Module, .site-hot-line .ModuleContent {
    @apply h-full {} }

.menu-toggle {
  @apply center-item items-center h-full mt-1 {} }

@screen xl {
  .menu-toggle {
    @apply hidden {} } }

.mobile-nav-wrap {
  @apply fixed top-[60px] left-0 w-full h-full bg-white z-[122] {}  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09);
  width: 320px;
  height: calc(100vh - 60px);
  transform: translateX(-100%);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }
  @media screen and (min-width: 1200px) {
    .mobile-nav-wrap {
      @apply hidden {} } }
  .mobile-nav-wrap.is-open {
    transform: none; }

.map-wrapper {
  @apply h-full {} }
  .map-wrapper a {
    display: block;
    height: 100%;
    @apply overflow-hidden {}    text-align: center;
    width: 100%; }
    @media screen and (max-width: 1024px) {
      .map-wrapper a {
        height: 400px; } }
    @media screen and (max-width: 576px) {
      .map-wrapper a {
        height: 300px; } }
    .map-wrapper a iframe {
      @apply inline-block w-full h-full {} }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: block;
  line-height: 1.25; }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.login-box, .wrap-register, .wrap-recover, .wrap-resetpass {
  max-width: 400px;
  margin: 60px auto;
  padding: 1.875rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03); }

.login-logo, .pagetitle, .postname, .wrap-recover h1 {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.375;
  font-weight: 700;
  margin-bottom: 1.25rem; }

.posttitle, .wrap-resetpass h1 {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  line-height: 1.375; }

.postname {
  text-align: left; }

.login-box-msg {
  font-size: 1.125rem;
  margin-bottom: 1rem; }

.login-box input[type=text], .login-box input[type=password], .login-box select, .wrap-register input[type=text], .wrap-register input[type=password], .wrap-register select, .account-form input[type=text], .account-form input[type=password], .account-form select, .address-form input[type=text], .address-form input[type=password], .address-form select, .wrap-recover input[type=text], .wrap-recover input[type=password], .wrap-recover select, .wrap-resetpass input[type=text], .wrap-resetpass input[type=password], .wrap-resetpass select {
  border: 0 !important;
  border-bottom: 1px solid #d7d7d7 !important;
  padding: 12px !important;
  width: 100% !important;
  height: auto !important; }

.login-box input[type="submit"], .wrap-register input[type="submit"], .account-form input[type="submit"], .address-form input[type="submit"], .wrap-recover input[type="submit"], .wrap-resetpass input[type="submit"] {
  line-height: 100%;
  cursor: pointer;
  width: 100%;
  color: #fff !important;
  background: #171e26 !important;
  padding: 15px 60px;
  border: none;
  text-transform: uppercase;
  font-size: 1rem; }

.login-box input:disabled, .wrap-register input:disabled, .account-form input:disabled, .address-form input:disabled, .wrap-recover input:disabled, .wrap-resetpass input:disabled {
  color: #666;
  background-color: transparent;
  font-style: italic; }

.wrap-register .settingrow > *, .account-form .settingrow > *, .address-form .settingrow > *, .wrap-recover .settingrow > *, .wrap-resetpass .settingrow > * {
  flex: 0 0 100%;
  max-width: 100%; }

.wrap-register .form-group, .account-form .form-group, .address-form .form-group, .wrap-recover .form-group, .wrap-resetpass .form-group {
  margin-bottom: 20px; }
  .wrap-register .form-group label, .account-form .form-group label, .address-form .form-group label, .wrap-recover .form-group label, .wrap-resetpass .form-group label {
    line-height: 1.5; }

.passwordrecovery input[type=submit], .wrap-register input[type=submit] {
  margin: 0 auto; }

.fa-exclamation-triangle {
  font-size: 12px;
  color: red;
  line-height: 2; }
  .fa-exclamation-triangle::before {
    display: none; }

.profile-container {
  padding: 3rem 15px; }

.user-sidebar, .user-sidelink {
  padding: 1.875rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03); }

.userinfo figure {
  display: flex;
  align-items: center; }
  .userinfo figure img {
    border-radius: 50%; }
  .userinfo figure figcaption {
    padding-left: 20px;
    line-height: 1.375;
    @apply text-grey-700 {} }

.userinfo .user-name {
  font-weight: 700;
  font-size: 1.125rem; }

.user-sidelink {
  margin-top: 1.875rem; }
  .user-sidelink ul li + li {
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 15px; }
  .user-sidelink ul li.active a {
    color: red; }

.account-info, .coin-info, .cart-history, .address-info, .order-info, .order-information, .order-product, .order-total {
  padding: 1.25rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  line-height: 1.375; }
  .account-info .link, .coin-info .link, .cart-history .link, .address-info .link, .order-info .link, .order-information .link, .order-product .link, .order-total .link {
    color: #5bc0de;
    font-size: 0.875rem; }

.info-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem; }
  .info-heading .posttitle, .info-heading .postname {
    margin-bottom: 0; }
  .info-heading .link {
    color: #5bc0de;
    font-size: 0.875rem; }

.account-info .info-detail .group .label {
  color: #787878; }

.address-list .address-col + .address-col {
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  border-top: 1px dashed #eee; }

.address-list .address {
  font-size: 0.875rem;
  position: relative; }
  .address-list .address .type {
    align-items: center;
    background-color: red;
    border-radius: 4px;
    color: #fff;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: .75em;
    padding-right: .75em;
    white-space: nowrap;
    margin-bottom: .9375rem;
    position: absolute;
    right: 0;
    top: 0; }
  .address-list .address .btn-editaddr {
    color: #5bc0de;
    text-decoration: underline;
    text-underline-position: under;
    display: inline-block;
    margin-top: 0.625rem; }
  .address-list .address .btn-deleteaddr {
    color: #999;
    margin-left: 0.625rem; }

.cart-history, .order-product {
  overflow: auto; }
  .cart-history table, .order-product table {
    width: 100%;
    font-size: 14px; }
    .cart-history table thead th, .order-product table thead th {
      font-weight: 700;
      padding: .75rem .75rem;
      border-bottom: 1px solid #dbdbdb; }
    .cart-history table tbody td, .order-product table tbody td {
      border: 1px solid #dbdbdb;
      border-width: 0 0 1px;
      padding: .75rem .75rem;
      vertical-align: top; }
      .cart-history table tbody td a, .order-product table tbody td a {
        display: block;
        color: #5bc0de; }
        .cart-history table tbody td a + .name, .order-product table tbody td a + .name {
          margin-top: 5px; }
        .cart-history table tbody td a:hover, .order-product table tbody td a:hover {
          text-decoration: underline; }
    .cart-history table tbody tr:last-child td, .order-product table tbody tr:last-child td {
      border-bottom: 0; }

.order-product table td {
  text-align: center; }
  .order-product table td .product {
    display: flex;
    align-items: center; }
    .order-product table td .product .img {
      width: 80px; }
    .order-product table td .product .caption {
      flex: 1 1 0%;
      padding-left: 20px;
      font-size: 14px; }

.order-total {
  width: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-top: 1rem;
  font-size: 14px; }
  .order-total table {
    width: 100%; }
    .order-total table tr td {
      text-align: right;
      padding: .5rem .75rem; }
      .order-total table tr td.total {
        font-weight: 700;
        font-size: 18px;
        color: red; }
    .order-total table tr:first-child td {
      padding-top: 0; }

.regcomplete span {
  color: #6cc070;
  display: block;
  text-align: center;
  margin-bottom: 20px; }

.order-information .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ccc; }

.order-information ul {
  font-size: 14px; }
  .order-information ul li.name {
    font-size: 16px; }

.order-detail-wrap .order-note {
  @apply my-4 p-4 mt-16 {}  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09); }
  .order-detail-wrap .order-note .note-title-wrapper {
    @apply text-16 font-bold {} }

.order-detail-wrap .postname {
  padding-left: 15px; }

.order-detail-wrap .cart-display {
  border: none; }
  .order-detail-wrap .cart-display .product-item {
    box-shadow: none; }
  @media screen and (max-width: 1024px) {
    .order-detail-wrap .cart-display .btn-primary {
      @apply my-2 {} } }
  .order-detail-wrap .cart-display .btn-primary span {
    @apply text-16 font-medium {} }
  .order-detail-wrap .cart-display .cart-wrapper {
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
    @apply p-8 bg-white {} }
  .order-detail-wrap .cart-display .total-wrapper {
    @apply flex justify-center items-end flex-col pt-8 {} }
    @media screen and (max-width: 1024px) {
      .order-detail-wrap .cart-display .total-wrapper .total-table {
        @apply w-full {} } }
    .order-detail-wrap .cart-display .total-wrapper .total-table tr:nth-child(2) td:last-child {
      @apply font-normal {} }
    .order-detail-wrap .cart-display .total-wrapper .total-table tr:last-child td:last-child {
      @apply text-primary {} }
    .order-detail-wrap .cart-display .total-wrapper .total-table td {
      @apply text-16 font-bold text-grey-700 py-2 {} }
      .order-detail-wrap .cart-display .total-wrapper .total-table td:last-child {
        @apply pl-16 {} }

@screen lg {
  .order-detail-wrap .cart-display .total-wrapper .total-table td:last-child {
    @apply pl-32 {} } }
    .order-detail-wrap .cart-display .total-wrapper .btn-right {
      @apply pt-12 {} }
      @media screen and (max-width: 767px) {
        .order-detail-wrap .cart-display .total-wrapper .btn-right {
          @apply flex flex-col justify-center items-center w-full {} } }
  .order-detail-wrap .cart-display .carttable {
    @apply w-full {}    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09); }
    @media screen and (max-width: 960px) {
      .order-detail-wrap .cart-display .carttable thead {
        @apply hidden {} } }
    .order-detail-wrap .cart-display .carttable thead th {
      background: #f0f0f0;
      height: 50px;
      @apply text-16 text-grey-700 uppercase font-medium px-4 text-center py-3 normal-case {} }
      .order-detail-wrap .cart-display .carttable thead th:first-child {
        @apply text-left pl-8 {} }
    .order-detail-wrap .cart-display .carttable tr:nth-child(even) td {
      background: #f2f2f2; }
    .order-detail-wrap .cart-display .carttable tr:last-child td {
      @apply pb-8 {} }
      @media screen and (max-width: 960px) {
        .order-detail-wrap .cart-display .carttable tr:last-child td {
          @apply pb-6 {} }
          .order-detail-wrap .cart-display .carttable tr:last-child td:last-child {
            @apply pb-8 {} } }
    .order-detail-wrap .cart-display .carttable tr td {
      @apply px-6 pt-8 pb-4 font-medium {}      width: 15%; }
      .order-detail-wrap .cart-display .carttable tr td:first-child {
        width: 55%; }
    @media screen and (max-width: 960px) {
      .order-detail-wrap .cart-display .carttable tr {
        @apply flex flex-col {}        flex-wrap: wrap; }
        .order-detail-wrap .cart-display .carttable tr td {
          @apply block py-0 pb-4 {}          width: 100%; }
          .order-detail-wrap .cart-display .carttable tr td:first-child {
            width: 100%;
            @apply pt-6 {} }
          .order-detail-wrap .cart-display .carttable tr td:nth-child(3), .order-detail-wrap .cart-display .carttable tr td:nth-child(4) {
            @apply inline-block {} }
          .order-detail-wrap .cart-display .carttable tr td:last-child {
            @apply pb-6 {} } }
  .order-detail-wrap .cart-display .product-item {
    @apply flex flex-row justify-start items-center mb-0 {} }
    .order-detail-wrap .cart-display .product-item .image a {
      display: block;
      height: 120px;
      max-width: 120px;
      min-width: 120px;
      width: auto; }
      .order-detail-wrap .cart-display .product-item .image a img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .order-detail-wrap .cart-display .product-item .caption {
      @apply ml-4 {} }
      .order-detail-wrap .cart-display .product-item .caption .title {
        @apply mb-0 {} }
        .order-detail-wrap .cart-display .product-item .caption .title a {
          @apply text-16 text-grey-700 block mb-1 font-medium whitespace-normal {}          max-width: 200px; }
      .order-detail-wrap .cart-display .product-item .caption .remove-item {
        @apply flex items-start justify-start {} }
        .order-detail-wrap .cart-display .product-item .caption .remove-item em {
          @apply text-primary block mr-2 {}          font-size: 16px;
          height: 16px; }
        .order-detail-wrap .cart-display .product-item .caption .remove-item span {
          @apply text-grey-700 text-16 font-medium {} }
  .order-detail-wrap .cart-display .price {
    @apply flex items-end justify-center flex-col {} }
    @media screen and (max-width: 960px) {
      .order-detail-wrap .cart-display .price {
        @apply items-start {} }
        .order-detail-wrap .cart-display .price .current {
          @apply pl-2 {} } }
    .order-detail-wrap .cart-display .price .current {
      @apply text-18 text-grey-700 mb-2 font-medium {} }
    .order-detail-wrap .cart-display .price .old-wrap {
      @apply flex {} }
      .order-detail-wrap .cart-display .price .old-wrap .old {
        @apply text-16 text-grey-700 font-medium line-through whitespace-nowrap {} }
      .order-detail-wrap .cart-display .price .old-wrap .discount {
        @apply text-primary text-16 font-medium whitespace-nowrap {} }
      .order-detail-wrap .cart-display .price .old-wrap span {
        @apply px-2 font-medium {} }
        .order-detail-wrap .cart-display .price .old-wrap span:not(:last-child) {
          border-right: 1px solid #e1e1e1; }
        .order-detail-wrap .cart-display .price .old-wrap span:last-child {
          @apply pr-0 {} }
  .order-detail-wrap .cart-display .total {
    @apply text-16 text-grey-700 font-bold text-center {} }
    @media screen and (max-width: 960px) {
      .order-detail-wrap .cart-display .total {
        @apply text-left {} } }
  @media screen and (max-width: 1024px) {
    .order-detail-wrap .cart-display .quantity-wrapper {
      @apply pt-0 pb-2 {} } }
  .order-detail-wrap .cart-display .quantity-wrapper .item-quantity {
    height: 40px;
    border-radius: 5px;
    @apply center-item {} }
    .order-detail-wrap .cart-display .quantity-wrapper .item-quantity input {
      height: 35px; }
    .order-detail-wrap .cart-display .quantity-wrapper .item-quantity .input-group-btn {
      height: 38px; }
  .order-detail-wrap .cart-display .quantity-wrapper .qty-minus, .order-detail-wrap .cart-display .quantity-wrapper .qty-plus {
    background: #e6e6e6; }
    .order-detail-wrap .cart-display .quantity-wrapper .qty-minus:hover, .order-detail-wrap .cart-display .quantity-wrapper .qty-plus:hover {
      background: none; }
  .order-detail-wrap .cart-display .quantity-wrapper .qty-minus {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .order-detail-wrap .cart-display .quantity-wrapper .qty-plus {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }

.order-detail-wrap .user-page .full-width-row {
  width: 100% !important; }

.order-detail-wrap .full-width-row {
  margin-top: 30px; }

.order-detail-wrap .admin-title {
  margin-bottom: 30px; }

.order-detail-wrap .second-row-status .title {
  @apply mb-4 {} }

.order-detail-wrap .cart-display .wrapper {
  @apply overflow-x-auto {} }
  .order-detail-wrap .cart-display .wrapper table {
    @apply w-full whitespace-nowrap {} }

.order-detail .cart-display {
  @apply bg-white pt-0 mt-16 pb-0 {} }
  @media screen and (max-width: 767px) {
    .order-detail .cart-display .wrapper {
      @apply whitespace-normal {} } }
  .order-detail .cart-display .product-item .caption {
    @apply mb-0 {} }

.order-detail .bottom-wrap {
  @apply flex justify-between items-center mt-4 {} }

.order-detail .admin-title {
  @apply p-4 py-6 {}  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09); }
  .order-detail .admin-title .text strong {
    @apply text-primary {} }

.order-detail .btn-back {
  @apply pt-4 pl-0 {} }
  .order-detail .btn-back:hover span {
    @apply underline {} }

.order-detail .status span {
  @apply font-medium {} }

.order-detail .title {
  @apply text-16 text-grey-700 font-bold uppercase mb-2 {} }

.order-detail .item {
  @apply h-full py-8 px-5 bg-white {}  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09); }
  .order-detail .item ul li {
    @apply mb-1 block font-medium {} }
    .order-detail .item ul li p {
      @apply text-15 font-bold mb-1 {} }
    .order-detail .item ul li span {
      @apply text-15 font-normal text-grey-700 {} }
    .order-detail .item ul li .status {
      @apply pt-2 {} }
      .order-detail .item ul li .status.pending {
        @apply text-primary {} }

.order-detail .carttable {
  border: none;
  @apply p-8 {} }
  .order-detail .carttable thead th {
    @apply text-16 font-bold whitespace-nowrap {} }
    @media screen and (max-width: 1024px) {
      .order-detail .carttable thead th {
        @apply whitespace-normal {} } }
  .order-detail .carttable tr td {
    @apply text-16 {} }
    .order-detail .carttable tr td:nth-child(3) {
      @apply text-center {} }
      @media screen and (max-width: 1024px) {
        .order-detail .carttable tr td:nth-child(3) {
          @apply text-left {} } }
  .order-detail .carttable span {
    @apply whitespace-nowrap {} }

.order-detail .total-wrapper {
  @apply pr-5 py-5 {} }
  @media screen and (max-width: 1024px) {
    .order-detail .total-wrapper {
      @apply p-5 {} } }
  .order-detail .total-wrapper .total-table td {
    @apply text-18 {} }

.order-detail .product-item .caption .title a {
  @apply normal-case {} }

.navigation-wrapper {
  @apply gap-item {} }
  @media screen and (max-width: 576px) {
    .navigation-wrapper {
      @apply flex-col {}      align-items: flex-end; } }
  @media screen and (max-width: 576px) {
    .navigation-wrapper .btn-left {
      @apply pb-8 w-full {} } }
  .navigation-wrapper .temp-total-price {
    margin-bottom: 15px; }
    .navigation-wrapper .temp-total-price .price-wrapper {
      @apply flex justify-end {} }
      .navigation-wrapper .temp-total-price .price-wrapper .text {
        font-size: 20px;
        color: #333;
        display: inline-block;
        padding-right: 30px; }
        @media screen and (max-width: 576px) {
          .navigation-wrapper .temp-total-price .price-wrapper .text {
            @apply text-16 {} } }
      .navigation-wrapper .temp-total-price .price-wrapper .price-text {
        font-size: 30px;
        line-height: 30px;
        height: 30px;
        font-weight: 700;
        width: 250px;
        @apply text-right text-primary {} }
        @media screen and (max-width: 576px) {
          .navigation-wrapper .temp-total-price .price-wrapper .price-text {
            @apply text-24 {} } }
        @media screen and (max-width: 576px) {
          .navigation-wrapper .temp-total-price .price-wrapper .price-text {
            width: 180px; } }
  .navigation-wrapper .vat-text {
    margin-bottom: 15px;
    text-align: right;
    font-size: 0.72917rem;
    line-height: 14px;
    height: 14px;
    color: #666666;
    font-style: italic; }
  .navigation-wrapper .wrap-right {
    @apply col-ver {} }

.commentpanel {
  @apply hidden {} }
  .commentpanel .btnShare {
    min-width: auto !important; }

.product-rating {
  @apply pt-8 overflow-hidden {} }
  .product-rating .container {
    @apply mx-auto {} }
  .product-rating .block-title {
    @apply mb-2 {} }
  .product-rating .commentpanel {
    @apply block {} }
  .product-rating .form-wrap {
    @apply border  rounded-[20px] overflow-hidden {} }
  .product-rating .product-review {
    @apply flex flex-wrap items-center {} }
    .product-rating .product-review .medium-rating {
      @apply w-full text-center {} }

@screen md {
  .product-rating .product-review .medium-rating {
    @apply text-left {}    width: 20%; } }
      @media screen and (max-width: 768px) {
        .product-rating .product-review .medium-rating {
          border-right: 0;
          @apply w-full mb-6 {} } }
      .product-rating .product-review .medium-rating .point {
        font-size: clamp(32px, 2.1875rem, 42px);
        @apply font-extrabold {} }

@screen lg {
  .product-rating .product-review .medium-rating .point {
    font-size: 60px; } }
    .product-rating .product-review .total-comment {
      @apply text-grey-700 font-normal {}      font-size: clamp(14px, 0.83333rem, 16px); }
    .product-rating .product-review .start-overview {
      @apply w-full {} }

@screen md {
  .product-rating .product-review .start-overview {
    width: 75%; } }

@screen lg {
  .product-rating .product-review .start-overview {
    padding: 0 50px;
    width: 50%;
    border-right: 1px solid #D9D9D9; } }
      @media screen and (max-width: 576px) {
        .product-rating .product-review .start-overview {
          @apply px-4 {} } }
      .product-rating .product-review .start-overview ul li:not(:last-child) {
        @apply mb-4 {} }
      .product-rating .product-review .start-overview ul li label {
        display: flex !important;
        @apply mr-2  font-semibold {}        font-size: clamp(14px, 0.83333rem, 16px); }
        .product-rating .product-review .start-overview ul li label i {
          background: url("/Data/Sites/1/skins/default/js/rating/star-on.png");
          @apply block ml-2 {}          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
      .product-rating .product-review .start-overview ul li .percent {
        @apply font-medium ml-3 {}        font-size: clamp(14px, 0.83333rem, 16px);
        min-width: 130px; }
      .product-rating .product-review .start-overview .chart {
        @apply flex {} }
    .product-rating .product-review .btn-wrap {
      @apply col-ver h-full {}      padding-left: 20px;
      width: 30%; }
      @media screen and (max-width: 1024px) {
        .product-rating .product-review .btn-wrap {
          @apply w-full pt-6 {} } }
      .product-rating .product-review .btn-wrap .btn-comment {
        height: 40px;
        @apply text-white font-semibold center-item  px-6 w-fit {}        border-radius: 4px;
        margin: 10px auto;
        font-size: clamp(14px, 0.83333rem, 16px); }
      .product-rating .product-review .btn-wrap p {
        @apply font-medium text-center {}        margin-bottom: 1.25rem;
        font-size: clamp(16px, 1.04167rem, 20px); }
    .product-rating .product-review .comment-form {
      @apply w-full {}      flex: 1 1 auto; }
      .product-rating .product-review .comment-form .module-title {
        @apply font-bold pt-0   font-semibold  normal-case {}        font-size: clamp(16px, 1.04167rem, 20px);
        margin-bottom: 1.5625rem; }
  .product-rating .commentpanel .comment-form-bottom {
    @apply flex py-4 px-5 border-t {}    flex-direction: row; }
    @media screen and (max-width: 1024px) {
      .product-rating .commentpanel .comment-form-bottom {
        @apply flex-col {} } }
  .product-rating .rating-wrap {
    @apply flex items-center mr-auto {} }
    @media screen and (max-width: 1024px) {
      .product-rating .rating-wrap {
        @apply mb-4 {} } }
    .product-rating .rating-wrap span {
      @apply font-normal {}      font-size: clamp(14px, 0.83333rem, 16px); }
  .product-rating .comment-form .form-group {
    @apply mb-0 {} }
    @media screen and (max-width: 1024px) {
      .product-rating .comment-form .form-group {
        @apply mb-4 mx-0 {} } }
  .product-rating .comment-form .form-group .rating-input {
    @apply flex ml-2 {}    height: 16px; }
  .product-rating .product-review .start-overview ul li .progress {
    background: #E0E0E0; }
  .product-rating .journalContent {
    @apply w-full px-5 pt-3 {}    height: 120px; }

@screen lg {
  .product-rating .journalContent {
    height: 150px; } }
  .product-rating .commentpanel .btnShare {
    width: 110px;
    height: 38px;
    @apply text-white font-semibold  relative rounded-[4px] px-4 center-item {}    font-size: clamp(14px, 0.83333rem, 16px);
    display: flex !important; }
  .product-rating .commentpanel .comment-form-bottom .form-group {
    min-width: auto !important; }

@screen sm {
  .product-rating .commentpanel .comment-form-bottom .form-group {
    @apply mx-4 {} } }
  .product-rating .commentpanel .comment-form-bottom .form-group input {
    min-width: 220px;
    background: #F0F0F0;
    height: 40px;
    border-radius: 4px;
    width: 220px;
    border: none;
    padding: 4px 24px; }
    @media screen and (max-width: 1600px) {
      .product-rating .commentpanel .comment-form-bottom .form-group input {
        min-width: 250px;
        width: 250px; } }
    @media screen and (max-width: 1280px) {
      .product-rating .commentpanel .comment-form-bottom .form-group input {
        min-width: auto !important;
        @apply w-full {} } }
  .product-rating .comment-list {
    border-top: 0 !important; }
    .product-rating .comment-list .user {
      @apply mt-3 {} }
      .product-rating .comment-list .user .img {
        @apply rounded-full overflow-hidden {} }
  .product-rating .product-review .start-overview ul li label {
    justify-content: flex-end; }

@media screen and (max-width: 576px) {
  .comment-list {
    margin-top: 0 !important;
    padding-top: 10px !important; } }

.comment-list .cmtinfo a {
  @apply px-5 py-2  text-white font-semibold center-item mt-3 w-fit mr-auto rounded-[4px] {} }

.comment-list .comment-items .item .user {
  @apply mr-4 {} }
  .comment-list .comment-items .item .user .img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px; }

.comment-list .comment-items .item .caption {
  padding-left: 0 !important; }
  .comment-list .comment-items .item .caption .mod {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #e8e8e8;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 400;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    -webkit-transition: background .1s ease;
    transition: background .1s ease;
    font-size: 0.75rem; }

.comment-list .comment-items .item .name {
  @apply text-14 font-bold uppercase {} }

.comment-list .comment-items .item .mod {
  @apply pl-1 {} }

.comment-list .comment-items .item time {
  @apply hidden {} }

.comment-list .cmteditarea, .comment-list .cmtbtn {
  @apply hidden {} }

.comment-list .cmteditarea {
  border: 1px solid #d6d6d6; }
  .comment-list .cmteditarea textarea {
    @apply p-4 w-full h-full {} }

.comment-list abbr {
  @apply inline-block ml-2 text-grey-700 text-12 {} }

.comment-list .cmt-item .jcmt {
  @apply w-full p-4 {}  padding: 0 !important;
  margin-left: 140px; }
  .comment-list .cmt-item .jcmt .cmt-area {
    @apply mb-4 {} }

.comment-list .cmt-item .cmt-area {
  @apply pb-3 {}  border-bottom: 1px solid #f1f1f1; }

.comment-list .jsummary .des {
  @apply my-1 {} }

.comment-list .jsummary .mod {
  @apply text-12 {} }

.comment-list .jsummary .name {
  @apply font-normal inline-block pr-2 {}  color: #223771;
  font-weight: 700;
  margin-bottom: 5px; }
  .comment-list .jsummary .name strong {
    color: #223771;
    font-weight: 700;
    margin-bottom: 5px; }

.comment-list .jsummary .cmlike {
  @apply text-14 font-bold {} }
  .comment-list .jsummary .cmlike span {
    @apply hidden {} }

.comment-list .cmreply {
  @apply text-14 font-bold {} }

.comment-list .item .caption .name {
  color: #223771;
  font-weight: 700;
  margin-bottom: 5px; }

.minidel {
  @apply hidden {} }

.cmtbtn .cmtname, .cmtbtn .cmtemail {
  @apply hidden {} }

.progress {
  @apply center-item relative {}  height: 10px;
  overflow: hidden;
  background-color: #e9ecef;
  border-radius: 4px;
  width: 100%; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  @apply absolute-y left-0 h-full {} }

.wrap-form {
  @apply relative {} }
  .wrap-form .row {
    box-shadow: none !important; }
  .wrap-form .fa-exclamation-triangle {
    @apply absolute font-light text-primary {}    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    bottom: -20px;
    left: 15px; }
    @media screen and (max-width: 576px) {
      .wrap-form .fa-exclamation-triangle {
        font-size: 10px;
        @apply font-bold {} } }
  .wrap-form .col {
    @apply mb-4 relative {} }
    .wrap-form .col:last-child {
      @apply mb-6 {} }
  .wrap-form .form-group {
    @apply relative {}    margin-bottom: 30px !important; }
    @media screen and (max-width: 576px) {
      .wrap-form .form-group {
        margin-bottom: 25px !important; } }
    .wrap-form .form-group input[type='text'], .wrap-form .form-group textarea, .wrap-form .form-group select {
      height: 44px;
      @apply text-white bg-transparent border-b border-b-white border-opacity-20 {}      font-size: clamp(13px, 0.72917rem, 14px); }
      @media screen and (max-width: 1200px) {
        .wrap-form .form-group input[type='text'], .wrap-form .form-group textarea, .wrap-form .form-group select {
          font-size: clamp(14px, 0.83333rem, 16px);
          height: 40px; } }
      .wrap-form .form-group input[type='text']::placeholder, .wrap-form .form-group textarea::placeholder, .wrap-form .form-group select::placeholder {
        @apply font-normal text-white {}        font-size: clamp(13px, 0.72917rem, 14px); }
        @media screen and (max-width: 1200px) {
          .wrap-form .form-group input[type='text']::placeholder, .wrap-form .form-group textarea::placeholder, .wrap-form .form-group select::placeholder {
            font-size: clamp(14px, 0.83333rem, 16px); } }
  .wrap-form .form-group.form-select {
    @apply relative overflow-hidden {} }
    .wrap-form .form-group.form-select::after {
      content: '';
      @apply block absolute-y pointer-events-none right-[35px]  z-10  transition {}      width: 1.25rem;
      height: 11px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml;charset=utf-8,<svg width='23' height='11' viewBox='0 0 23 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.1875 0.53125C1.32812 0.34375 1.51562 0.25 1.75 0.25C1.89062 0.25 2.07812 0.296875 2.21875 0.4375L11.4531 8.92188L20.7344 0.4375C21.0156 0.15625 21.4844 0.15625 21.7656 0.484375C22.0469 0.765625 22.0469 1.23438 21.7188 1.51562L11.9688 10.5156C11.6875 10.7969 11.2656 10.7969 10.9844 10.5156L1.23438 1.51562C0.90625 1.28125 0.90625 0.8125 1.1875 0.53125Z' fill='%232F4394'/></svg>"); }
      @media screen and (max-width: 1200px) {
        .wrap-form .form-group.form-select::after {
          width: 16px;
          height: 11px;
          height: 40px; } }
    .wrap-form .form-group.form-select select {
      height: 44px;
      @apply rounded-none w-full  text-grey-700 {}      padding-right: 50px !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-size: clamp(13px, 0.72917rem, 14px); }
      @media screen and (max-width: 1200px) {
        .wrap-form .form-group.form-select select {
          font-size: clamp(14px, 0.83333rem, 16px);
          height: 40px; } }
      .wrap-form .form-group.form-select select option {
        font-size: clamp(13px, 0.72917rem, 14px);
        @apply text-grey-700 {} }
        @media screen and (max-width: 1200px) {
          .wrap-form .form-group.form-select select option {
            font-size: clamp(14px, 0.83333rem, 16px); } }
      .wrap-form .form-group.form-select select::-ms-expand {
        display: none; }
  .wrap-form .form-group textarea {
    @apply p-4 w-full {}    height: 7rem; }

@screen lg {
  .wrap-form .form-group textarea {
    height: 120px; } }
  .wrap-form .frm-btnwrap {
    @apply start-item w-full mt-6 {} }
    @media screen and (max-width: 1200px) {
      .wrap-form .frm-btnwrap {
        @apply mt-8 {}        margin-bottom: 0 !important; } }
    .wrap-form .frm-btnwrap label {
      display: none !important; }
    .wrap-form .frm-btnwrap .frm-btn {
      @apply relative {} }
      .wrap-form .frm-btnwrap .frm-btn:hover::after {
        @apply text-white {} }
      .wrap-form .frm-btnwrap .frm-btn:hover input[type='submit'] {
        @apply text-white {} }
      .wrap-form .frm-btnwrap .frm-btn::after {
        font-family: 'Font Awesome 6 Pro';
        content: '\f061';
        @apply block absolute pointer-events-none top-50 translate-y-50 right-[25px]  z-10  transition font-normal {}        height: 18px;
        width: 20px;
        line-height: 18px;
        ont-size: clamp(18px, 1.04167rem, 20px); }
        @media screen and (max-width: 1024px) {
          .wrap-form .frm-btnwrap .frm-btn::after {
            right: 20px; } }
      .wrap-form .frm-btnwrap .frm-btn input[type='submit'] {
        width: 110px;
        height: 48px;
        @apply transition font-medium center-item px-6 pr-12 {}        font-size: clamp(14px, 0.83333rem, 16px); }
        @media screen and (max-width: 1024px) {
          .wrap-form .frm-btnwrap .frm-btn input[type='submit'] {
            height: 40px;
            @apply px-3  pr-8 {} } }

.frm-btn-reset {
  display: none; }

.frm-captcha {
  margin: 40px 0 0  0 !important;
  display: inline-flex;
  flex-direction: row-reverse; }
  .frm-captcha img {
    height: 44px !important;
    max-width: auto;
    max-width: 150px; }
    @media screen and (max-width: 1200px) {
      .frm-captcha img {
        max-width: 150px; } }
  @media screen and (max-width: 576px) {
    .frm-captcha {
      flex-direction: column-reverse; } }
  .frm-captcha .frm-captcha-input {
    @apply mt-0 {}    margin-left: 50px; }
    @media screen and (max-width: 576px) {
      .frm-captcha .frm-captcha-input {
        margin-left: 0;
        @apply mt-4 {} } }
    .frm-captcha .frm-captcha-input label {
      display: none; }
    .frm-captcha .frm-captcha-input input {
      padding: 0 10px;
      margin-top: 0;
      height: 30px; }
      @media screen and (max-width: 1200px) {
        .frm-captcha .frm-captcha-input input {
          max-width: 200px; } }
      @media screen and (max-width: 1200px) {
        .frm-captcha .frm-captcha-input input {
          max-width: 150px; } }
      @media screen and (max-width: 1024px) {
        .frm-captcha .frm-captcha-input input {
          max-width: 200px; } }
      @media (max-width: 767.98px) {
        .frm-captcha .frm-captcha-input input {
          margin-left: 20px; } }
      @media (max-width: 576.98px) {
        .frm-captcha .frm-captcha-input input {
          margin-left: 0; } }
  .frm-captcha .rcRefreshImage {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 0;
    width: 0;
    right: -5px;
    top: 5px;
    color: #ffffff;
    z-index: 0;
    font-size: 0; }
    .frm-captcha .rcRefreshImage:before {
      font-family: 'Font Awesome 6 Pro';
      position: absolute;
      top: 0;
      right: -40px;
      opacity: 1;
      z-index: 99;
      color: #9d9080;
      font-size: 30px;
      content: "\f01e";
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      @media screen and (max-width: 576px) {
        .frm-captcha .rcRefreshImage:before {
          height: 50px; } }

@media screen and (max-width: 500px) {
  #ctl00_mainContent_ctl05_captcha_ctl00 {
    display: none; } }

#ctl00_mainContent_ctl02_captcha_ctl01 {
  position: relative; }

#ctl00_mainContent_ctl06_btnReset, #ctl00_mainContent_ctl05_captcha_ctl00, #ctl00_mainContent_ctl08_captcha_ctl00, #ctl00_mainContent_ctl07_captcha_ctl00 {
  display: none; }

#ctl00_mainContent_ctl06_txtCaptcha {
  margin-left: 10px; }

#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton #text {
  display: none; }

.RadCaptcha {
  @apply relative {} }
  @media screen and (max-width: 767.5px) {
    .RadCaptcha {
      margin-left: 0; } }
  .RadCaptcha span {
    @apply hidden {} }
  .RadCaptcha #ctl00_mainContent_ctl04_captcha_ctl00 {
    display: block !important;
    position: absolute;
    left: 0;
    top: 100%;
    @apply text-primary text-12 {} }
  .RadCaptcha > div > div {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    height: 40px !important;
    margin-top: 0; }
    @media (max-width: 767.98px) {
      .RadCaptcha > div > div {
        margin-right: 0;
        width: 180px; } }

.language-wrapper {
  @apply p-0 relative overflow-hidden flex  mx-16 {}  z-index: 121; }

@screen xl {
  .language-wrapper {
    @apply mx-32 {} } }
  .language-wrapper ul {
    @apply start-item {} }
  .language-wrapper li {
    @apply pointer-events-none {} }
    .language-wrapper li:not(:last-child) a {
      border-right: 1px solid #ccc; }
    .language-wrapper li.active a {
      @apply text-blue-primary {} }
  .language-wrapper a {
    @apply text-grey-700 font-normal uppercase leading-none center-item {}    padding: 0 8px;
    font-size: clamp(20px, 1.25rem, 24px); }

@screen lg {
  .language-wrapper a {
    font-size: clamp(18px, 1.04167rem, 20px); }
    .language-wrapper a:hover {
      @apply underline {} } }

.flex-wrap {
  display: flex;
  justify-content: center; }

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px; }
  .login-page .login-panel .sitelogo {
    text-align: center;
    margin-bottom: 40px;
    width: 100%; }
  .login-page .login-panel img {
    height: 80px; }
  .login-page .login-panel .headtitle {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px; }
    .login-page .login-panel .headtitle:after {
      content: '';
      margin: 10px auto; }

.login-page .input-group {
  @apply relative {} }

.icheck-primary {
  margin: 15px 0 30px; }
  .icheck-primary label {
    @apply ml-1 {} }

.input-group-append {
  @apply absolute left-0 bottom-0 {} }

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0; } }

@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px; } }

.social-login {
  @apply hidden {} }

.login-form {
  width: 100%;
  margin: 0 auto; }
  .login-form .fa-exclamation-triangle {
    @apply absolute top-full left-0 text-12 text-primary pt-1 {} }
  .login-form .panel-heading {
    display: none; }
  .login-form .title {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    border-left: 3px solid #8c0000;
    padding-left: 10px;
    margin-bottom: 30px; }
  .login-form .module-title {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 20px; }
  .login-form a {
    color: #8c0000; }
  .login-form .form-group {
    margin-bottom: 20px;
    position: relative; }
    .login-form .form-group label {
      color: #999999;
      text-transform: uppercase;
      font-size: 0rem;
      display: block;
      width: 50px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid #e6e6e6;
      @apply text-14  text-grey-700 {} }
      .login-form .form-group label .fa {
        font-size: 1.125rem;
        line-height: 40px; }
  .login-form input {
    width: 350px;
    font-size: .875rem;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    padding: 10px 30px 10px 60px;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
    .login-form input::placeholder {
      font-size: 15px; }
    .login-form input:active, .login-form input:hover, .login-form input:focus {
      border-color: #999999; }
    @media screen and (max-width: 400px) {
      .login-form input {
        width: 300px; } }
  .login-form a {
    display: inline-block;
    color: #000;
    padding: 10px 0;
    margin-right: 15px; }
    .login-form a:hover {
      color: #8c0000; }
  .login-form .forget {
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 30px; }
    .login-form .forget input[type='checkbox'] {
      @apply opacity-0 relative {} }
      .login-form .forget input[type='checkbox']:checked ~ label::before {
        content: '\F0132'; }
    .login-form .forget label {
      color: #333333;
      font-weight: 300;
      width: auto;
      height: auto;
      text-align: left;
      padding-left: 30px;
      line-height: 1.5;
      font-size: 0.875rem;
      text-transform: none;
      position: relative;
      -webkit-transition: all, 0.5s;
      transition: all, 0.5s; }
      .login-form .forget label:before {
        font-family: 'Material Design Icons';
        content: '\F0131';
        font-size: 20px;
        display: block;
        line-height: 14px;
        font-size: 16px;
        color: #333333;
        position: absolute;
        top: 2px;
        left: 1px;
        -webkit-transition: all, 0.5s;
        transition: all, 0.5s; }
      .login-form .forget label:hover {
        color: #333333; }
  .login-form .btn-login {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
    background: #8c0000;
    display: block;
    border-radius: 5px;
    overflow: hidden; }
    .login-form .btn-login:hover::after {
      transform: translateX(3px); }
    .login-form .btn-login:before {
      background: #c61017; }
    .login-form .btn-login:after {
      transition: .3s all  ease-in-out;
      font: normal normal normal 24px/1 Material Design Icons;
      content: '\F0142';
      position: absolute;
      top: 12px;
      right: 15px; }
    .login-form .btn-login[type='reset'] {
      display: none; }
  .login-form .go-signup {
    text-align: center;
    margin-top: 15px; }
    .login-form .go-signup a {
      display: block;
      clear: both;
      color: #8c0000; }
      .login-form .go-signup a:hover {
        color: #000; }

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%; } }

.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px; }
  .login-bg .login-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .login-bg .login-img .login-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0.5;
      z-index: 1; }
  .login-bg img {
    height: 100%;
    width: auto;
    max-width: unset;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .login-bg .login-des {
    position: relative;
    z-index: 1;
    color: #fff; }
    .login-bg .login-des .title {
      font-size: 2.875rem;
      font-weight: 300;
      margin-bottom: 20px; }
    .login-bg .login-des .module-title {
      font-size: 1.625rem;
      font-weight: 200;
      opacity: 0.9; }
    .login-bg .login-des .des {
      margin-top: 180px;
      font-size: 1.25rem; }
      .login-bg .login-des .des span {
        opacity: 0.8;
        font-weight: 200;
        display: inline-block;
        margin-right: 10px; }
      .login-bg .login-des .des a {
        display: inline-block;
        opacity: 1;
        font-weight: 300;
        font-size: 2rem;
        color: #fff; }
        .login-bg .login-des .des a:after {
          content: '\f105';
          font-family: 'Font Awesome 6 Pro';
          margin-left: 5px; }
        .login-bg .login-des .des a:hover {
          color: #8c0000; }

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  .login-bg {
    padding: 30px; } }

@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1); } }

@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem; } }

@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px; } }

@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem; } }

.panel-primary span {
  color: #eb0c22;
  font-style: italic; }

#ctl00_mainContent_login1_LoginCtrl_lnkPasswordRecovery, #ctl00_mainContent_login1_LoginCtrl_lnkRegisterExtraLink {
  display: none; }

.pop-up-modal {
  padding: 0;
  border-radius: 10px;
  @apply w-full {} }

.carousel__button.is-close {
  top: 0 !important;
  width: 50px;
  height: 50px;
  right: 0 !important; }

.pop-upmodal-wrapper {
  overflow: hidden; }
  .pop-upmodal-wrapper .container {
    padding: 0 40px;
    @apply h-full flex items-center {} }

@screen lg {
  .pop-upmodal-wrapper .container {
    padding: 0 80px; } }
  .pop-upmodal-wrapper .title-modal {
    @apply text-24 font-medium text-white text-left pb-4  uppercase relative {} }

@screen lg {
  .pop-upmodal-wrapper .title-modal {
    @apply text-32 pb-6 {} } }
    .pop-upmodal-wrapper .title-modal::after {
      content: '';
      @apply block absolute pointer-events-none left-0 bottom-0 z-10 bg-white transition {}      height: 2px;
      width: 95px; }
  .pop-upmodal-wrapper .sub-text {
    @apply text-18 text-white mb-2 {} }
  .pop-upmodal-wrapper .scroll-wrapper {
    @apply text-16 text-white pt-4 {} }

@screen lg {
  .pop-upmodal-wrapper .scroll-wrapper {
    @apply pt-6 {} } }
    .pop-upmodal-wrapper .scroll-wrapper * {
      @apply text-16 text-white mb-4 {} }

@screen lg {
  .pop-upmodal-wrapper .scroll-wrapper * {
    @apply mb-5 {} } }
  .pop-upmodal-wrapper .btn-left {
    @apply pt-4 {} }

@screen lg {
  .pop-upmodal-wrapper .btn-left {
    @apply pt-6 {} } }
  @media screen and (max-width: 1024px) {
    .pop-upmodal-wrapper .scroll-wrapper {
      height: 250px; } }

.search-page {
  padding: 70px 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 992px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: "\f002";
      font-family: 'Font Awesome 6 Pro';
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 30px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.search-wrapper {
  @apply flex items-center m-0 {} }

.search-toggle em {
  font-size: 16px;
  @apply text-blue-primary {} }

@media screen and (max-width: 1200px) {
  .search-toggle {
    @apply hidden {} } }

@media screen and (max-width: 1200px) {
  .searchbox {
    @apply hidden w-full  bg-white {}    padding: 0 1px; }
    .searchbox .container {
      @apply p-0 h-full center-item {} }
    .searchbox .search-wrapper-bg {
      @apply w-full relative {} }
    .searchbox input[type='text'], .searchbox button {
      height: 40px; }
    .searchbox input[type='text'] {
      @apply w-full   bg-white pl-4  text-grey-700 border-grey-700 border border-opacity-50 {}      font-size: clamp(14px, 0.83333rem, 16px);
      padding-right: 50px; }
      .searchbox input[type='text']::placeholder {
        @apply text-left  text-grey-700 {}        font-size: clamp(14px, 0.83333rem, 16px); }
    .searchbox button {
      width: 40px;
      @apply absolute-y right-0  border border-transparent transition pointer-events-none {} }
      .searchbox button:focus, .searchbox button:hover {
        @apply bg-white {} }
      .searchbox button em {
        font-size: 18px;
        @apply transition text-white {} } }

@screen xl {
  .searchbox {
    @apply fixed pointer-events-none  left-50 translate-x-50  w-full  bg-white opacity-0 transition w-full  flex justify-center items-center z-20 {}    height: calc( 100vh - 80px);
    transition: .5s all ease-in-out;
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.1);
    top: 80px; }
    .searchbox .container {
      @apply mx-auto center-item {} }
      @media screen and (max-width: 1200px) {
        .searchbox .container {
          @apply w-full {}          padding: 0 !important; } }
    .searchbox .search-wrapper-bg {
      position: relative;
      display: flex;
      @apply bg-white  h-[50px]  w-8/12 {} }
    .searchbox.open {
      z-index: 20;
      @apply pointer-events-auto opacity-100 {} }
    .searchbox input[type="text"] {
      @apply relative w-full font-normal transition  z-20 {}      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      font-size: 20px;
      margin: 0 auto;
      outline: none;
      text-align: left;
      display: flex;
      padding-right: 60px;
      height: 40px;
      padding-left: 0; }
      .searchbox input[type="text"]::placeholder {
        @apply text-left {}        font-size: clamp(14px, 0.83333rem, 16px); }
    .searchbox button {
      @apply absolute top-0 right-0 center-item h-full  z-40 text-center cursor-pointer {}      width: 40px;
      height: 40px; }
      .searchbox button em {
        color: #333;
        font-size: 20px;
        z-index: 99;
        font-size: 26px;
        @apply transition {} } }

.document-list {
  @apply relative {} }
  .document-list .container {
    @apply relative z-40 {} }
  .document-list::after {
    content: '';
    @apply block absolute pointer-events-none right-0 bottom-0 z-10 transition w-full h-full {}    max-width: 36.71875rem;
    background: rgba(231, 246, 239, 0.5); }
  .document-list .document-item {
    border-radius: 20px;
    box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05);
    @apply bg-white {} }
    .document-list .document-item:hover .title {
      @apply text-blue-primary {} }
      .document-list .document-item:hover .title * {
        @apply text-blue-primary {} }

.document-item {
  @apply px-8 py-6 h-full start-item {}  align-items: flex-start;
  border-radius: 60px 0; }
  @media screen and (max-width: 1024px) {
    .document-item {
      border-radius: 30px 0;
      @apply p-6 {} } }
  .document-item time {
    @apply mb-1 text-grey-500 leading-none {}    font-size: clamp(14px, 0.83333rem, 16px); }
  .document-item .title {
    @apply font-medium  transition text-grey-500 transition leading-tight {}    font-size: clamp(15px, 0.9375rem, 18px); }
    .document-item .title * {
      @apply font-medium  transition text-grey-500 transition {}      font-size: clamp(15px, 0.9375rem, 18px); }
  .document-item:hover .title {
    @apply text-green-primary underline {} }
    .document-item:hover .title * {
      @apply text-green-primary underline {} }
  .document-item .icon {
    @apply center-item  mr-20 pt-1 {}    width: 64px;
    min-width: 64px; }
    .document-item .icon img {
      width: auto;
      height: 100%;
      object-fit: contain; }
  .document-item .btn-view-more {
    @apply mt-2 {} }

@media screen and (max-width: 576px) {
  .gallery-list .row {
    margin: 0 -5px -10px -5px; }
    .gallery-list .row .col {
      padding: 0 5px;
      margin-bottom: 10px; } }

@screen lg {
  .gallery-list .col:nth-child(3), .gallery-list .col:nth-child(4), .gallery-list .col:nth-child(5) {
    width: 33.33%; } }

.video-figure {
  @apply relative {} }
  .video-figure .img {
    @apply relative rounded-[20px] overflow-hidden {} }
    .video-figure .img::before {
      content: '';
      @apply block absolute-center pointer-events-none z-20  transition {}      width: 4.6875rem;
      height: 3.125rem;
      background: url("../img/icon/play.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
      @media screen and (max-width: 1024px) {
        .video-figure .img::before {
          width: 3.125rem;
          height: 2.08333rem; } }
    .video-figure .img::after {
      content: '';
      @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full {}      opacity: 0.5;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }
    .video-figure .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 64.98801%; }
      .video-figure .img a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .video-figure .headline {
    @apply absolute-x bottom-0 pb-4 z-30 pointer-events-none {} }

.about-facility .tab-nav {
  padding: 0.72917rem 0 2.08333rem; }

.about-menu-list {
  @apply bg-blue-E6EFF7 relative overflow-hidden {} }
  .about-menu-list::after {
    content: '';
    @apply block absolute pointer-events-none left-0  z-10  transition {}    width: 310px;
    height: 360px;
    background: url("../img/overlay/1.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    bottom: -30px; }
  .about-menu-list .grid-center {
    margin-top: 2.08333rem; }

.gallery-document .grid-center {
  margin-top: 2.08333rem; }

.gallery-document .document-item {
  padding: 0; }
  .gallery-document .document-item:not(:last-child) {
    @apply mb-4 {} }
  .gallery-document .document-item .icon {
    min-width: 42px;
    width: 42px; }

.video-item {
  @apply relative {} }
  .video-item .img {
    @apply relative rounded-[20px] overflow-hidden {} }
    .video-item .img::before {
      content: '';
      @apply block absolute-center pointer-events-none z-20  transition {}      width: 4.6875rem;
      height: 3.125rem;
      background: url("../img/icon/play.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
      @media screen and (max-width: 1024px) {
        .video-item .img::before {
          width: 3.125rem;
          height: 2.08333rem; } }
    .video-item .img::after {
      content: '';
      @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full {}      opacity: 0.5;
      background: linear-gradient(180deg, rgba(0, 92, 171, 0) 0%, #0062AF 100%); }
    .video-item .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 75%; }
      .video-item .img a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .video-item .headline {
    @apply absolute-x bottom-0 pb-4 z-30 pointer-events-none {} }

.facility-item .img {
  @apply relative {}  @apply overflow-hidden rounded-[20px] {} }
  .facility-item .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 75%; }
    .facility-item .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.facility-item .headline {
  padding: 0.83333rem 0 1.25rem; }

.facility-item ul li {
  @apply start-item mb-16 {}  align-items: flex-start; }
  .facility-item ul li em {
    @apply inline-block  mr-4 text-blue-primary {}    font-size: 16px; }
  .facility-item ul li span, .facility-item ul li p {
    @apply text-grey-700 font-normal leading-tight mb-0 {}    word-break: break-word;
    font-size: clamp(15px, 0.9375rem, 18px); }

.facility-item ul table {
  @apply hidden {} }

.support-policy {
  @apply relative bg-blue-E6EFF7 {} }
  .support-policy::after {
    content: '';
    @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full {}    height: 85%;
    background: url("../img/overlay/overlay-2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center; }
  .support-policy .container {
    @apply z-30 relative {} }
  .support-policy .row {
    @apply mb-0 {} }

@screen lg {
  .support-policy .row:nth-child(odd) .txt {
    padding-left: 8%; } }
    .support-policy .row:nth-child(even) {
      @apply flex-row-reverse {} }
  .support-policy .img {
    @apply rounded-[20px] overflow-hidden {} }
    .support-policy .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 75%; }
      .support-policy .img a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .support-policy .txt {
    @apply col-hor h-full {} }
  .support-policy .headline {
    @apply text-blue-primary mb-16 {} }
    .support-policy .headline * {
      @apply text-blue-primary {} }

.link-faq {
  background: lightgray -1.631px -142.293px / 112.685% 131.947% no-repeat; }
  @media screen and (max-width: 768px) {
    .link-faq {
      @apply pb-10 {} } }
  .link-faq .img {
    @apply flex items-end justify-center {}    height: 24.47917rem;
    width: auto; }
    .link-faq .img img {
      width: auto;
      height: 100%;
      object-fit: contain; }
  .link-faq .txt {
    @apply col-hor h-full pb-4 {} }
  .link-faq .block-title {
    @apply mb-4 {} }
  .link-faq .desc {
    @apply mb-3 {} }

@screen lg {
  .link-faq .desc {
    @apply mb-6 {} } }
    .link-faq .desc p:not(:last-child) {
      @apply mb-1 {} }

@screen lg {
  .link-faq .desc p:not(:last-child) {
    @apply mb-2 {} } }

.faq-display .block-wrap {
  @apply bg-white {}  padding: 1.5625rem;
  box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.1); }

@screen lg {
  .faq-display .block-wrap {
    padding: 2.08333rem 1.5625rem; } }

.toggle-item {
  @apply overflow-hidden {} }
  .toggle-item.active .title {
    @apply text-green-primary border-b-green-primary {} }
    .toggle-item.active .title * {
      @apply text-green-primary {} }
    .toggle-item.active .title span {
      -webkit-line-clamp: unset; }
    .toggle-item.active .title em:before {
      content: '\f068'; }
  .toggle-item.active .article {
    @apply block {} }
  .toggle-item .title {
    @apply gap-item bg-white transition py-3 border-b border-b-grey-500 {}    min-height: 45px; }

@screen lg {
  .toggle-item .title {
    @apply py-2 {}    min-height: 52px; } }
    .toggle-item .title p {
      @apply start-item w-full m-0 font-Judson {} }
    .toggle-item .title strong {
      @apply mr-3 font-bold text-grey-700  font-Judson {}      font-size: clamp(16px, 1.04167rem, 20px); }
    .toggle-item .title span {
      @apply text-grey-700  font-normal font-Judson {}      font-size: clamp(16px, 1.04167rem, 20px); }
    .toggle-item .title em {
      @apply text-grey-700 ml-5 inline-block {}      font-size: 18px;
      height: 18px;
      width: 16px;
      transition: .3s all  ease-in-out; }
      @media screen and (max-width: 1024px) {
        .toggle-item .title em {
          font-size: 16px;
          height: 16px;
          width: 16px; } }
  .toggle-item .article {
    @apply hidden  bg-white text-grey-700  pt-3 {}    padding: 1.25rem 0;
    font-size: clamp(15px, 0.9375rem, 18px); }

@screen lg {
  .toggle-item .article {
    padding: 1.5625rem 0; } }
    .toggle-item .article * {
      @apply text-grey-700 {}      font-size: clamp(15px, 0.9375rem, 18px); }
    .toggle-item .article p {
      @apply block mb-4 {} }
    .toggle-item .article ul {
      @apply mt-4 {}      padding-left: 20px;
      list-style-type: disc; }
      .toggle-item .article ul li {
        @apply mb-4 {} }
    .toggle-item .article strong, .toggle-item .article h3, .toggle-item .article h4 {
      @apply font-bold {} }
    .toggle-item .article img {
      @apply block mx-auto my-4 {} }
    .toggle-item .article ul {
      list-style-type: disc; }
      .toggle-item .article ul li {
        @apply mb-2 {} }

.policy-list .tab-nav {
  @apply bg-transparent {}  margin-bottom: 2.08333rem; }
  @media screen and (max-width: 1024px) {
    .policy-list .tab-nav {
      @apply justify-start overflow-x-auto {} }
      .policy-list .tab-nav ul {
        @apply whitespace-nowrap justify-start {} } }

@screen lg {
  .policy-list .tab-nav {
    margin-bottom: 2.60417rem; } }
  .policy-list .tab-nav ul {
    @apply bg-transparent {}    box-shadow: none; }
  .policy-list .tab-nav li {
    @apply bg-transparent {} }
    .policy-list .tab-nav li.active a {
      @apply text-blue-primary {} }
      .policy-list .tab-nav li.active a::after {
        @apply opacity-100 {} }
  .policy-list .tab-nav a {
    background: white !important;
    @apply text-grey-300 font-bold relative uppercase {} }
    .policy-list .tab-nav a::after {
      content: '';
      @apply block absolute-x pointer-events-none  z-10 bg-blue-primary opacity-0  transition w-full h-[1px] bottom-0 {} }

.policy-list .title-wrap {
  @apply gap-item {} }
  @media screen and (max-width: 1024px) {
    .policy-list .title-wrap {
      @apply col-ver {} } }
  .policy-list .title-wrap .block-title {
    @apply text-grey-700 {} }
  .policy-list .title-wrap .btn-view-more em {
    @apply ml-3 {} }

.policy-list .fullcontent {
  @apply border-t border-t-grey-100 pt-4 {} }
  .policy-list .fullcontent h2 {
    @apply text-grey-700 font-Judson !important {} }
  .policy-list .fullcontent h3, .policy-list .fullcontent h4, .policy-list .fullcontent h5 {
    font-size: clamp(15px, 0.9375rem, 18px); }
  .policy-list .fullcontent ul, .policy-list .fullcontent ol {
    list-style-type: none;
    padding-left: 0; }
    .policy-list .fullcontent ul li, .policy-list .fullcontent ol li {
      @apply relative {} }
      .policy-list .fullcontent ul li:before, .policy-list .fullcontent ol li:before {
        @apply text-grey-700 {}        content: '-';
        display: inline-block;
        pointer-events: none;
        z-index: 9;
        height: 16px;
        width: 16px;
        transition: .3s all ease-in-out; }
  .policy-list .fullcontent img {
    @apply overflow-hidden rounded-[20px] {} }

@screen lg {
  .policy-list .fullcontent img {
    @apply rounded-[40px] {} } }

.als-news-detail .side-social-wrap {
  @apply pt-4 {} }
  @media screen and (max-width: 1024px) {
    .als-news-detail .side-social-wrap {
      @apply start-item {} } }

@screen lg {
  .als-news-detail .side-social-wrap {
    @apply absolute top-0 left-[-60px] z-30 {} } }
  .als-news-detail .side-social-wrap li:not(:last-child) {
    @apply mr-3 {} }

@screen lg {
  .als-news-detail .side-social-wrap li:not(:last-child) {
    @apply mb-[10px] mr-0 {} } }
  .als-news-detail .side-social-wrap a {
    @apply center-item rounded-full transition {}    border: 1px solid #E7e7e7;
    width: 40px;
    height: 40px; }
    .als-news-detail .side-social-wrap a em {
      @apply text-grey-500 leading-none transition {}      font-size: 16px; }
    .als-news-detail .side-social-wrap a:hover {
      @apply bg-blue-primary {} }
      .als-news-detail .side-social-wrap a:hover em {
        @apply text-white {} }

.als-news-detail time {
  font-size: 14px; }

.als-news-detail .fullcontent {
  @apply pt-4 border-t border-t-green-CDEDDD mt-3 {} }

.als-news-detail .zone-title {
  margin-bottom: 0.83333rem;
  @apply text-blue-primary font-normal {} }

@screen lg {
  .als-news-detail .zone-title {
    margin-bottom: 1.25rem; } }

.als-news-detail .news-side .headline a {
  display: -webkit-box;
  font-size: clamp(18px, 1.25rem, 24px);
  height: 1.625rem;
  min-height: 23.4px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; }

.als-news-detail .news-side .zone-time {
  @apply mb-2 {} }

.als-news-detail .news-side time, .als-news-detail .news-side .zone-type {
  font-size: 12px; }

.als-news-detail .news-side .desc {
  display: -webkit-box;
  font-size: clamp(14px, 0.9375rem, 18px);
  height: 2.4375rem;
  min-height: 36.4px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.als-information {
  @apply relative overflow-hidden {} }
  .als-information::after {
    content: '';
    width: 449px;
    height: 595px;
    background: url("../img/overlay/3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 20vh;
    left: 0;
    z-index: -1;
    @apply block absolute pointer-events-none transition {} }
    @media (max-width: 767.98px) {
      .als-information::after {
        width: 220px; } }
  .als-information .container {
    @apply relative z-40 {} }

.facility-list {
  @apply relative {} }
  .facility-list::after {
    content: '';
    width: 449px;
    height: 595px;
    background: url("../img/overlay/3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 20vh;
    left: 0;
    z-index: -1;
    @apply block absolute pointer-events-none transition {} }
    @media (max-width: 767.98px) {
      .facility-list::after {
        width: 220px; } }
  .facility-list::after {
    bottom: unset;
    top: 17.70833rem; }
  .facility-list .container {
    @apply relative z-40 {} }
  .facility-list .tab-wrapper {
    @apply mt-40 {} }
  .facility-list .row {
    @apply border border-green-primary rounded-[20px] bg-white {}    box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05);
    margin: 0;
    padding: 1.04167rem; }

@screen sm {
  .facility-list .row {
    padding: 1.5625rem; } }

@screen lg {
  .facility-list .row {
    padding: 2.08333rem; } }
    .facility-list .row:not(:last-child) {
      margin: 0 0 30px 0; }
    .facility-list .row .col {
      margin: 0;
      padding: 0; }
    .facility-list .row:nth-child(even) {
      @apply flex-row-reverse {} }

@screen md {
  .facility-list .row:nth-child(even) .txt {
    @apply pl-0 pr-6 {} } }

@screen xl {
  .facility-list .row:nth-child(even) .txt {
    padding-right: 3.125rem; } }
  .facility-list .zone-desc {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    .facility-list .zone-desc p + p {
      @apply hidden {} }
  .facility-list .img {
    @apply overflow-hidden rounded-[20px]  my-auto {} }

@screen lg {
  .facility-list .img {
    @apply rounded-[40px] {} } }
    .facility-list .img a {
      @apply w-full block {}      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 75%; }
      .facility-list .img a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  @media screen and (max-width: 768px) {
    .facility-list .txt {
      @apply pt-6 {} } }

@screen md {
  .facility-list .txt {
    @apply pl-6 {} } }

@screen xl {
  .facility-list .txt {
    padding-left: 3.125rem; } }
  .facility-list .txt ul {
    @apply mt-4 {}    @apply text-grey-500 {} }
    .facility-list .txt ul li {
      @apply start-item mb-16 {}      align-items: flex-start; }
      .facility-list .txt ul li em {
        @apply inline-block  mr-4 text-blue-primary {}        font-size: 16px; }
      .facility-list .txt ul li span, .facility-list .txt ul li p {
        @apply text-grey-700 font-normal leading-tight mb-0 {}        word-break: break-word;
        font-size: clamp(15px, 0.9375rem, 18px); }
    .facility-list .txt ul * {
      @apply text-grey-500 {} }
    .facility-list .txt ul li span, .facility-list .txt ul li p, .facility-list .txt ul li strong {
      @apply text-grey-500 {} }
    .facility-list .txt ul table {
      @apply hidden {} }
  .facility-list .headline {
    @apply mb-16 font-normal {} }
    .facility-list .headline * {
      @apply font-normal {} }
  .facility-list .desc {
    @apply text-grey-500 {} }
    .facility-list .desc * {
      @apply text-grey-500 {} }
  .facility-list .wrap-left {
    @apply mt-4 {} }

.facility-detail {
  @apply relative {} }
  .facility-detail::after {
    content: '';
    width: 449px;
    height: 595px;
    background: url("../img/overlay/3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 20vh;
    left: 0;
    z-index: -1;
    @apply block absolute pointer-events-none transition {} }
    @media (max-width: 767.98px) {
      .facility-detail::after {
        width: 220px; } }
  .facility-detail::after {
    bottom: unset;
    top: 17.70833rem; }

@screen lg {
  .facility-detail .scollbar-wrap {
    height: 11.97917rem; } }
  .facility-detail .container {
    @apply relative z-30 {} }
  .facility-detail .block-wrap {
    @apply relative z-30 bg-blue-primary text-white font-normal {}    padding: 1.04167rem; }
    .facility-detail .block-wrap * {
      @apply text-white font-normal {} }

@screen lg {
  .facility-detail .block-wrap {
    padding: 1.5625rem; } }
  .facility-detail .headline {
    @apply font-normal text-white mb-4 {} }

@screen lg {
  .facility-detail .headline {
    @apply mb-5 {} } }
    .facility-detail .headline * {
      @apply font-normal text-white {} }
  .facility-detail ul li {
    @apply start-item mb-16 {}    align-items: flex-start; }
    .facility-detail ul li em {
      @apply inline-block  mr-4 text-blue-primary {}      font-size: 16px; }
    .facility-detail ul li span, .facility-detail ul li p {
      @apply text-grey-700 font-normal leading-tight mb-0 {}      word-break: break-word;
      font-size: clamp(15px, 0.9375rem, 18px); }
  .facility-detail ul em {
    @apply font-light {} }
  .facility-detail ul em, .facility-detail ul span, .facility-detail ul p {
    @apply text-white font-normal {} }
  .facility-detail ul span, .facility-detail ul p {
    font-size: clamp(15px, 0.9375rem, 18px); }
  .facility-detail table tr td {
    @apply text-white font-normal {}    font-size: clamp(15px, 0.9375rem, 18px); }
    .facility-detail table tr td:first-child {
      @apply pl-6 {} }
    .facility-detail table tr td * {
      @apply text-white font-normal {}      font-size: clamp(15px, 0.9375rem, 18px); }
  .facility-detail .single-swiper {
    @apply pt-8 {} }

@screen lg {
  .facility-detail .single-swiper {
    @apply pt-12 {} } }
    .facility-detail .single-swiper .img {
      @apply rounded-[20px] overflow-hidden {} }
      @media screen and (max-width: 1024px) {
        .facility-detail .single-swiper .img a {
          position: relative;
          display: block;
          height: 0;
          overflow: hidden;
          padding-top: 60%; }
          .facility-detail .single-swiper .img a img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: .3s ease-in-out all; } }

@screen lg {
  .facility-detail .single-swiper .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 41.66667%; }
    .facility-detail .single-swiper .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; } }

.global-breadcrumb {
  @apply flex justify-start items-center py-2 bg-grey-50 {}  min-height: 50px; }
  .global-breadcrumb ol {
    @apply p-0 list-none {} }
    .global-breadcrumb ol li {
      @apply inline-flex relative leading-none {}      padding: 0 14px; }
      .global-breadcrumb ol li:after {
        content: '\f054';
        font-family: 'Font Awesome 6 Pro';
        @apply text-grey-500 block font-normal absolute-y pointer-events-none z-10 left-full {}        font-size: 16px;
        height: 16px;
        width: 12px; }
        @media screen and (max-width: 1024px) {
          .global-breadcrumb ol li:after {
            font-size: 14px; } }
      .global-breadcrumb ol li:first-child {
        @apply pl-0 {} }
        .global-breadcrumb ol li:first-child span, .global-breadcrumb ol li:first-child a {
          font-size: 0 !important; }
        .global-breadcrumb ol li:first-child:before {
          content: '\f015';
          font-family: 'Font Awesome 6 Pro';
          @apply text-grey-500 block pointer-events-none z-10 font-light {}          font-size: 16px;
          height: 16px;
          width: 16px; }
      .global-breadcrumb ol li:last-child:after {
        @apply hidden {} }
      .global-breadcrumb ol li:last-child a {
        @apply text-grey-500 {} }
      .global-breadcrumb ol li a, .global-breadcrumb ol li span {
        font-size: clamp(14px, 0.83333rem, 16px);
        @apply text-grey-500 font-normal {} }

.top-banner .img-wrap {
  @apply relative {} }
  @media screen and (max-width: 1024px) {
    .top-banner .img-wrap {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 56.25%; }
      .top-banner .img-wrap img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; } }

@screen lg {
  .top-banner .img-wrap {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 24.47917%; }
    .top-banner .img-wrap img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; } }

.modulepager, .pagination-wrap {
  @apply flex items-center justify-center pt-8 pb-0 {}  padding-bottom: 30px; }
  .modulepager ul, .pagination-wrap ul {
    padding: 0;
    margin: 0;
    position: relative; }
    .modulepager ul li, .pagination-wrap ul li {
      display: inline-block;
      padding: 0 10px; }
      .modulepager ul li.active a, .modulepager ul li.active span, .pagination-wrap ul li.active a, .pagination-wrap ul li.active span {
        @apply bg-blue-primary text-white border-blue-primary {} }
      .modulepager ul li:hover a, .modulepager ul li:hover span, .pagination-wrap ul li:hover a, .pagination-wrap ul li:hover span {
        @apply underline {} }
      .modulepager ul li span, .modulepager ul li a, .pagination-wrap ul li span, .pagination-wrap ul li a {
        width: 32px;
        height: 32px;
        text-decoration: none;
        @apply text-blue-primary font-Judson center-item transition rounded-full border border-green-primary {}        font-size: clamp(20px, 1.25rem, 24px); }
  .modulepager .next, .modulepager .prev, .pagination-wrap .next, .pagination-wrap .prev {
    @apply hidden {} }

.home-about {
  @apply relative {} }
  .home-about::after {
    content: '';
    @apply block absolute pointer-events-none left-0  z-10  transition {}    width: 310px;
    height: 360px;
    background: url("../img/overlay/1.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    bottom: -30px; }
  @media screen and (max-width: 1024px) {
    .home-about::after {
      @apply top-0 {} } }
  .home-about .container {
    @apply relative z-40 {} }
  .home-about .row {
    @apply justify-between {} }
  .home-about .img {
    @apply rounded-[20px] overflow-hidden {} }

@screen lg {
  .home-about .img {
    @apply rounded-[40px] {} } }
    .home-about .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 75%; }
      .home-about .img a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  @media screen and (max-width: 1024px) {
    .home-about .block-title {
      @apply text-center {} } }

.primary-banner {
  @apply relative overflow-hidden {} }
  .primary-banner .banner-container {
    @apply relative {} }
    @media screen and (max-width: 1200px) {
      .primary-banner .banner-container {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 73.33333%; }
        .primary-banner .banner-container .swiper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; } }
  .primary-banner .txt-wrap {
    @apply z-30 {} }

@screen sm {
  .primary-banner .txt-wrap {
    @apply absolute-center text-left {} } }
    @media screen and (max-width: 576px) {
      .primary-banner .txt-wrap {
        @apply absolute-x bottom-[50px] text-center {} } }
  .primary-banner .wrap {
    @apply relative {} }
    .primary-banner .wrap::after {
      content: '';
      @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full {}      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }
  .primary-banner .img {
    @apply relative {} }
    @media screen and (min-width: 1200.1px) {
      .primary-banner .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 44.79167%; }
        .primary-banner .img a img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; } }
    @media screen and (max-width: 1200px) {
      .primary-banner .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 73.33333%; }
        .primary-banner .img a img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; } }
  .primary-banner .pagination-wrap {
    @apply z-30 {} }

@screen xl {
  .primary-banner .pagination-wrap {
    @apply absolute-center {} } }
    @media screen and (max-width: 1200px) {
      .primary-banner .pagination-wrap {
        @apply absolute-x bottom-[30px]  center-item {} } }
    @media screen and (max-width: 576px) {
      .primary-banner .pagination-wrap {
        @apply bottom-[15px] {} } }

@screen xl {
  .primary-banner .swiper-pagination {
    width: fit-content !important;
    @apply center-item ml-3 {} } }
  @media screen and (max-width: 1200px) {
    .primary-banner .swiper-pagination {
      @apply w-full center-item {} } }
  .primary-banner .title {
    @apply font-normal leading-tight text-white font-Judson {}    font-size: clamp(24px, 1.66667rem, 32px); }

@screen sm {
  .primary-banner .title {
    padding-bottom: 150px; } }
    .primary-banner .title * {
      @apply font-normal leading-tight text-white font-Judson {}      font-size: clamp(24px, 1.66667rem, 32px); }

@screen lg {
  .primary-banner .title {
    max-width: 50%; } }
    @media screen and (max-width: 576px) {
      .primary-banner .title {
        font-size: clamp(20px, 1.25rem, 24px); }
        .primary-banner .title * {
          font-size: clamp(20px, 1.25rem, 24px); } }
  .primary-banner .swiper-pagination-bullet {
    @apply transition opacity-50 center-item bg-white relative  overflow-hidden {}    border-radius: 0 !important;
    height: 4px;
    width: 20px; }
    .primary-banner .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply opacity-100 border-white {}      width: 60px; }

@screen lg {
  .home-learn-more .item:hover .txt {
    @apply bottom-0 {} }
  .home-learn-more .item:hover .grid-center {
    @apply opacity-100 {} } }

.home-learn-more .img {
  @apply rounded-[20px] overflow-hidden relative {} }
  .home-learn-more .img::after {
    content: '';
    @apply block absolute pointer-events-none left-0 bottom-0 z-20  transition w-full h-full bg-gradient opacity-50 {} }
    @media screen and (max-width: 576px) {
      .home-learn-more .img::after {
        background: linear-gradient(180deg, rgba(0, 92, 171, 0.2) 0%, #0062AF 100%); } }
  .home-learn-more .img a {
    @apply z-10 {}    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 75%; }
    .home-learn-more .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.home-learn-more .txt {
  @apply absolute-x  z-30 pointer-events-none text-center w-full transition bottom-0 {}  padding: 1.04167rem; }

@screen lg {
  .home-learn-more .txt {
    padding: 0 1.5625rem;
    bottom: -60px; } }

.home-learn-more .grid-center {
  margin: 1.04167rem 0;
  @apply opacity-0 pointer-events-none transition {} }
  @media screen and (max-width: 1024px) {
    .home-learn-more .grid-center {
      @apply hidden {} } }

.home-info {
  @apply bg-blue-E6EFF7 {} }
  .home-info .block-title {
    @apply mb-8 {} }

@screen lg {
  .home-info .block-title {
    margin-bottom: 2.08333rem; } }

@screen lg {
  .home-info .swiper-slide:nth-child(even) .news-figure {
    @apply flex-col-reverse {} } }
  @media screen and (max-width: 576px) {
    .home-info .news-figure .zone-time {
      @apply col-hor {} }
      .home-info .news-figure .zone-time time {
        @apply mb-1 {} }
      .home-info .news-figure .zone-time .zone-type {
        @apply pl-0 {} }
        .home-info .news-figure .zone-time .zone-type::before {
          @apply opacity-0 {} } }

@screen lg {
  .home-info .news-figure .desc {
    display: -webkit-box;
    font-size: clamp(15px, 0.9375rem, 18px);
    height: 5.25rem;
    min-height: 84px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; } }
  .home-info .zone-type {
    display: none !important; }

.news-figure {
  @apply bg-white rounded-[20px] overflow-hidden col-ver {} }
  .news-figure .img {
    @apply w-full block {} }
    .news-figure .img a {
      @apply w-full block {}      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 75.65789%; }
      .news-figure .img a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .news-figure .txt {
    @apply w-full {}    padding: 1.25rem; }

@screen lg {
  .news-figure .txt {
    padding: 1.5625rem 1.25rem; } }
  .news-figure .zone-time {
    font-size: 14px; }
    .news-figure .zone-time * {
      font-size: 14px; }
  .news-figure .headline {
    padding: 0.83333rem 0; }
    @media screen and (max-width: 1024px) {
      .news-figure .headline a {
        display: -webkit-box;
        font-size: clamp(20px, 1.25rem, 24px);
        height: 3rem;
        min-height: 48px;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; } }

@screen lg {
  .news-figure .headline a {
    display: -webkit-box;
    font-size: clamp(20px, 1.25rem, 24px);
    height: 3rem;
    min-height: 48px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; } }
  @media screen and (max-width: 1024px) {
    .news-figure .desc {
      display: -webkit-box;
      font-size: clamp(15px, 0.9375rem, 18px);
      height: 2.625rem;
      min-height: 42px;
      line-height: 1.4;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; } }

@screen lg {
  .news-figure .desc {
    display: -webkit-box;
    font-size: clamp(15px, 0.9375rem, 18px);
    height: 6.5625rem;
    min-height: 105px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; } }
  .news-figure:hover .headline a {
    @apply text-green-primary {} }

@media screen and (max-width: 576px) {
  .home-support .row {
    margin: 0 -5px -10px -5px; }
    .home-support .row .col {
      padding: 0 5px;
      margin-bottom: 10px; } }

.home-support .block-title {
  @apply mb-8 {} }

@screen lg {
  .home-support .block-title {
    margin-bottom: 2.08333rem; } }

.home-support .item {
  @apply relative rounded-[8px] overflow-hidden {} }
  .home-support .item:hover .img::after {
    @apply opacity-50 {} }
  .home-support .item:hover .icon {
    @apply bg-blue-primary {} }
    .home-support .item:hover .icon img {
      filter: brightness(0) invert(1); }

.home-support .img {
  @apply relative {} }
  .home-support .img::after {
    content: '';
    @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full {}    background: rgba(10, 10, 10, 0.5); }
  .home-support .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.62651%; }
    .home-support .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
    .home-support .img a img {
      @apply transition {} }

.home-support .txt {
  @apply absolute-center w-full h-full col-ver z-30 pointer-events-none {}  justify-content: flex-end;
  padding: 1.04167rem; }

.home-support .icon {
  @apply center-item overflow-hidden mx-auto mb-16 bg-white rounded-full p-3 {}  height: 4.16667rem;
  width: 4.16667rem; }
  .home-support .icon img {
    width: auto;
    height: 100%;
    object-fit: contain; }

.home-treatment {
  @apply bg-blue-E7F6EF {} }
  .home-treatment .title-wrap {
    @apply border-b border-b-green-9BDABB {}    padding-bottom: 16px;
    margin-bottom: 2.08333rem; }

@screen lg {
  .home-treatment .title-wrap {
    margin-bottom: 2.70833rem; } }

@screen lg {
  .home-treatment .title-wrap .block-title {
    @apply mb-0 {} } }
    @media screen and (max-width: 1024px) {
      .home-treatment .title-wrap {
        @apply col-ver {} } }

.news-item .img {
  @apply rounded-[20px] overflow-hidden {} }
  .news-item .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 75%; }
    .news-item .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.news-item .zone-time {
  margin: 1.5625rem 0 1.04167rem; }

.news-item .desc {
  margin: 0.83333rem 0;
  display: -webkit-box;
  font-size: clamp(14px, 0.9375rem, 18px);
  height: 3.79688rem;
  min-height: 56.7px;
  line-height: 1.35;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }
  .news-item .desc * {
    display: -webkit-box;
    font-size: clamp(14px, 0.9375rem, 18px);
    height: 3.79688rem;
    min-height: 56.7px;
    line-height: 1.35;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; }

.news-item:hover .headline a {
  @apply text-green-primary {} }

section.menu-date {
  z-index: 2;
  @apply bg-blue-E6EFF7 relative overflow-hidden {} }
  section.menu-date::after {
    content: '';
    width: 449px;
    height: 595px;
    background: url("../img/overlay/3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 20vh;
    left: 0;
    z-index: -1;
    @apply block absolute pointer-events-none transition {} }
    @media (max-width: 767.98px) {
      section.menu-date::after {
        width: 220px; } }
  section.menu-date .btn-selection {
    @apply flex items-center relative ml-auto w-full md:w-fit {}    @apply min-w-[304px] {} }
    section.menu-date .btn-selection select {
      @apply appearance-none pl-5 pr-5 md:px-12 w-full text-center {} }
    section.menu-date .btn-selection::after {
      content: "\f078";
      @apply font-Awesome6 absolute right-6  text-[14px] {} }

.menu-detail {
  @apply relative lg:pb-20 pb-10 {} }
  @media (max-width: 1023.98px) {
    .menu-detail .swiper-nav-wrap .nav-prev, .menu-detail .swiper-nav-wrap .nav-next {
      @apply left-0 right-0 {} } }
  .menu-detail .swiper-nav-wrap a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 41.66667%; }
    .menu-detail .swiper-nav-wrap a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .menu-detail .single-swiper {
    @apply mb-8 {} }

@screen lg {
  .menu-detail .single-swiper {
    @apply mb-40 {} } }
    .menu-detail .single-swiper .swiper-slide img {
      border-radius: 1.04167rem; }
  .menu-detail .stats {
    @apply pb-5 {} }
    .menu-detail .stats ul {
      @apply center-item {} }
    .menu-detail .stats li {
      @apply col-hor text-center {}      padding: 0 1.5625rem; }

@screen lg {
  .menu-detail .stats li {
    padding: 0 2.08333rem; } }

@screen xl {
  .menu-detail .stats li {
    padding: 0 3.125rem; } }
      .menu-detail .stats li:not(:last-child) {
        @apply border-r border-r-grey-100 {} }
    .menu-detail .stats .icon {
      @apply center-item {}      height: 4.16667rem;
      width: 100%; }
      .menu-detail .stats .icon img {
        width: auto;
        height: 100%;
        object-fit: contain; }
  .menu-detail table {
    font-size: 0.83333rem; }
    .menu-detail table thead, .menu-detail table tbody, .menu-detail table td, .menu-detail table tr, .menu-detail table th {
      @apply border border-[#E7E7E7] p-2 {} }
    .menu-detail table thead {
      @apply bg-blue-primary font-bold text-white {} }
    .menu-detail table tr th:not(:first-child), .menu-detail table tr td:not(:first-child) {
      @apply text-center {} }
  .menu-detail .notes {
    font-size: 0.9375rem;
    @apply py-7 text-primary {} }
  .menu-detail h3.headline {
    font-size: 1.25rem;
    @apply text-blue-primary font-bold {} }
  .menu-detail .desc {
    @apply pt-3 text-center w-full {} }
  .menu-detail .desc-info {
    @apply text-green-primary text-center font-bold w-full {} }
  .menu-detail .more-info {
    @apply bg-[#E6EFF7] rounded-lg p-4 mt-7 lg:mb-20 mb-5 {} }
    .menu-detail .more-info .desc {
      @apply text-left {} }
  .menu-detail .format-content {
    font-size: 0.9375rem; }
    .menu-detail .format-content p {
      font-size: 0.9375rem;
      margin-bottom: 1.04rem; }
    .menu-detail .format-content h3, .menu-detail .format-content h4, .menu-detail .format-content h2 {
      font-size: 1.25rem;
      margin-bottom: 1.04rem;
      @apply font-Judson text-blue-primary font-bold {} }
    .menu-detail .format-content ul {
      margin-bottom: 1.04rem;
      gap: 0.41667rem;
      @apply list-disc pl-5 flex flex-col {} }
      .menu-detail .format-content ul li {
        font-size: 0.9375rem;
        color: #1A1A1A;
        line-height: 1.4; }
        .menu-detail .format-content ul li::marker {
          font-size: 14px;
          color: #1A1A1A; }
    .menu-detail .format-content fieldset {
      border: 0;
      padding: 1.25rem;
      margin: 0;
      min-width: 0;
      font-size: 0.9375rem;
      @apply bg-[#E6EFF7] {} }
      .menu-detail .format-content fieldset legend {
        font-size: 1.25rem;
        display: table;
        float: left;
        margin: 0;
        padding: 0;
        width: 100%;
        margin-bottom: 0.84rem;
        @apply font-Judson text-blue-primary font-bold {} }
        .menu-detail .format-content fieldset legend + * {
          clear: both; }
    .menu-detail .format-content .table-responsive-custom {
      margin-bottom: 1.04rem;
      @apply border border-[#E7E7E7] rounded-[10px] overflow-hidden {} }
      @media (max-width: 1023.98px) {
        .menu-detail .format-content .table-responsive-custom {
          @apply overflow-auto py-1 pt-0 {} } }
      @media (max-width: 1023.98px) {
        .menu-detail .format-content .table-responsive-custom table {
          @apply w-[1300px] {} } }
    .menu-detail .format-content table {
      margin: -1px; }
      .menu-detail .format-content table tr:nth-child(1) th {
        @apply w-[1%] {} }
        .menu-detail .format-content table tr:nth-child(1) th:nth-child(1) {
          @apply w-[15%] {} }
        .menu-detail .format-content table tr:nth-child(1) th:nth-child(2) {
          @apply w-[10%] {} }
        .menu-detail .format-content table tr:nth-child(1) th:nth-child(3) {
          @apply whitespace-nowrap {} }
        .menu-detail .format-content table tr:nth-child(1) th:nth-child(4) {
          @apply w-[18%] {} }
      .menu-detail .format-content table tr:nth-child(2) th:nth-child(1) {
        @apply whitespace-nowrap text-center {} }
      .menu-detail .format-content table tr:nth-child(2) th:nth-child(2) {
        @apply w-[15%] {} }
      .menu-detail .format-content table tr:nth-child(2) th:nth-child(3) {
        @apply w-[10%] {} }
      .menu-detail .format-content table tr:nth-child(2) th:nth-child(4) {
        @apply whitespace-nowrap {} }
      .menu-detail .format-content table tr:nth-child(2) th:nth-child(5) {
        @apply w-[10%] {} }
  .menu-detail .five-swiper {
    @apply -mx-5 lg:-mx-2 {} }
    .menu-detail .five-swiper .swiper {
      @apply py-5 px-5 lg:px-2 {} }
    .menu-detail .five-swiper .swiper-slide.active .menu-item {
      @apply bg-blue-primary {} }
      .menu-detail .five-swiper .swiper-slide.active .menu-item .txt h5 {
        @apply text-white {} }
      .menu-detail .five-swiper .swiper-slide.active .menu-item .txt h3 {
        @apply text-white {} }
    .menu-detail .five-swiper .swiper-slide .menu-item {
      @apply rounded-[16px] overflow-hidden p-2 transition-all duration-300 bg-white {}      box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05); }
      .menu-detail .five-swiper .swiper-slide .menu-item .img {
        @apply overflow-hidden pb-4 {}        @apply rounded-lg {}        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 61.73913%; }
        .menu-detail .five-swiper .swiper-slide .menu-item .img img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .menu-detail .five-swiper .swiper-slide .menu-item .txt {
        padding: 0.41667rem;
        @apply bg-transparent {} }
        .menu-detail .five-swiper .swiper-slide .menu-item .txt h5 {
          font-size: 0.83333rem;
          line-height: 1.14583rem;
          @apply transition-all duration-300 {} }
        .menu-detail .five-swiper .swiper-slide .menu-item .txt h3 {
          font-weight: 400;
          font-size: 1.04167rem;
          line-height: 1.45833rem;
          @apply transition-all duration-300 {} }
    .menu-detail .five-swiper .swiper-slide:hover .menu-item {
      @apply bg-blue-primary {} }
      .menu-detail .five-swiper .swiper-slide:hover .menu-item .txt h5 {
        @apply text-white {} }
      .menu-detail .five-swiper .swiper-slide:hover .menu-item .txt h3 {
        @apply text-white {} }

.other-menu {
  background-color: #E7F6EF80; }
  .other-menu .swiper-slide .menu-item .txt {
    @apply bg-white {} }
  .other-menu .swiper-slide .menu-item .headline a {
    @apply text-blue-primary {} }
  .other-menu .swiper-slide .menu-item:hover .headline a {
    @apply text-blue-primary {} }

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell; }

.menu-list {
  @apply bg-blue-E6EFF7 relative overflow-hidden {} }
  .menu-list::after {
    content: '';
    width: 449px;
    height: 595px;
    background: url("../img/overlay/3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 20vh;
    left: 0;
    z-index: -1;
    @apply block absolute pointer-events-none transition {} }
    @media (max-width: 767.98px) {
      .menu-list::after {
        width: 220px; } }
  .menu-list::after {
    bottom: unset;
    top: 17.70833rem; }
  .menu-list .container {
    @apply relative z-40 {} }
  .menu-list .wrap-block-title {
    @apply mb-8 {} }

@screen lg {
  .menu-list .wrap-block-title {
    margin-bottom: 2.60417rem; } }
  .menu-list .menu-item-nutrition {
    @apply h-full {} }
    .menu-list .menu-item-nutrition .txt {
      @apply h-full {} }
  .menu-list .row .col .menu-item-nutrition .txt {
    @apply bg-white {} }
  .menu-list .row .col .menu-item-nutrition .headline a {
    @apply text-blue-primary {} }
  .menu-list .row .col .menu-item-nutrition:hover .headline a {
    @apply text-green-primary {} }
  @media screen and (max-width: 576px) {
    .menu-list .row {
      margin: 0 -5px -10px -5px; }
      .menu-list .row .col {
        padding: 0 5px;
        margin-bottom: 10px; } }

.menu-item-nutrition {
  @apply rounded-[20px] overflow-hidden {} }
  .menu-item-nutrition .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 75%; }
    .menu-item-nutrition .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .menu-item-nutrition .txt {
    @apply bg-white {}    padding: 1.04167rem; }

@screen lg {
  .menu-item-nutrition .txt {
    padding: 1.35417rem 1.04167rem; } }
  .menu-item-nutrition .menu-hover ul li:hover {
    @apply text-blue-primary {} }
  .menu-item-nutrition .content ul {
    gap: 0.41667rem;
    @apply list-disc pl-5 flex flex-col {} }
  .menu-item-nutrition .content li {
    font-size: 0.72917rem;
    color: #4D4D4D;
    line-height: 1.4; }
    .menu-item-nutrition .content li::marker {
      font-size: 12px;
      color: #4D4D4D; }
  .menu-item-nutrition .headline a {
    @apply text-blue-primary {} }

.top-menu {
  z-index: 1;
  @apply bg-[#E6EFF7] overflow-hidden {} }
  .top-menu::after {
    content: '';
    width: 449px;
    height: 595px;
    background: url("../img/overlay/3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 20vh;
    left: 0;
    z-index: -1;
    @apply block absolute pointer-events-none transition {} }
    @media (max-width: 767.98px) {
      .top-menu::after {
        width: 220px; } }
  .top-menu::after {
    bottom: 0;
    top: auto;
    z-index: 2;
    width: 300px;
    height: 400px; }
  .top-menu .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 229.18919%; }
    .top-menu .img a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .top-menu .row .col {
    @apply md:mb-0 {} }
  .top-menu .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%; }
    .top-menu .img img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .top-menu h3 {
    font-size: 0.9375rem;
    @apply mb-3 {} }
  .top-menu .format-content p {
    font-size: 0.9375rem; }

.news-detail {
  padding: 3.125rem 0; }

@screen lg {
  .news-detail {
    padding: 5.20833rem 0; } }
  .news-detail .block-title {
    @apply mb-5 py-5  border-b normal-case {} }

.news-side {
  @apply start-item {}  align-items: flex-start;
  padding: 1.25rem 0 1.04167rem; }
  @media screen and (max-width: 576px) {
    .news-side {
      @apply col-hor {} } }
  .news-side:not(:last-child) {
    @apply border-b border-b-green-CDEDDD {} }
  .news-side:first-child {
    @apply pt-0 {} }
  .news-side:hover .headline a {
    @apply underline {} }
  .news-side .txt {
    padding-right: 1.04167rem; }
    @media screen and (max-width: 576px) {
      .news-side .txt {
        @apply order-2 {} } }

@screen sm {
  .news-side .txt {
    padding-right: 1.5625rem; } }
  .news-side time, .news-side .zone-type {
    font-size: 14px; }
  .news-side .zone-time {
    @apply mb-4 {} }
    @media screen and (min-width: 576px) and (max-width: 1600px) {
      .news-side .zone-time {
        @apply mb-2 {}        @apply col-hor {} }
        .news-side .zone-time .zone-type {
          @apply pl-0 mt-1 {} } }
  .news-side .headline a {
    display: -webkit-box;
    font-size: clamp(19px, 1.25rem, 24px);
    height: 3.25rem;
    min-height: 49.4px;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
  @media screen and (max-width: 576px) {
    .news-side .img-wrap {
      @apply w-full mb-4 {}      order: 1; } }
  .news-side .img-wrap a {
    @apply overflow-hidden rounded-[20px] {} }
    @media screen and (max-width: 576px) {
      .news-side .img-wrap a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
        @apply w-full {} }
        .news-side .img-wrap a img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; } }

@screen sm {
  .news-side .img-wrap a {
    @apply center-item mr-5 {}    height: 105px;
    width: 140px;
    min-width: 140px;
    max-width: 140px; }
    .news-side .img-wrap a img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

.fullcontent {
  @apply font-normal text-grey-700 leading-normal {}  font-size: clamp(15px, 0.9375rem, 18px); }
  .fullcontent * {
    @apply font-normal text-grey-700 leading-normal {}    font-size: clamp(15px, 0.9375rem, 18px); }
  .fullcontent img, .fullcontent iframe {
    @apply block mx-auto my-4 {} }
  .fullcontent p {
    @apply mb-3 {} }
  .fullcontent ul, .fullcontent ol {
    list-style-type: disc;
    padding-left: 20px; }
    .fullcontent ul li, .fullcontent ol li {
      @apply mb-2 {} }
  .fullcontent h2, .fullcontent h3, .fullcontent h4, .fullcontent h5 {
    @apply text-blue-primary mb-4 font-bold !important {} }
  .fullcontent strong {
    font-weight: 700 !important; }
    .fullcontent strong * {
      font-weight: 700 !important; }
  .fullcontent h2 {
    font-size: clamp(32px, 1.875rem, 36px); }
    @media screen and (max-width: 576px) {
      .fullcontent h2 {
        font-size: clamp(26px, 1.45833rem, 28px); } }
  .fullcontent h3 {
    font-size: clamp(28px, 1.66667rem, 32px); }
    @media screen and (max-width: 576px) {
      .fullcontent h3 {
        font-size: clamp(24px, 1.45833rem, 28px); } }
  .fullcontent h4 {
    font-size: clamp(24px, 1.45833rem, 28px); }
    @media screen and (max-width: 576px) {
      .fullcontent h4 {
        font-size: clamp(20px, 1.25rem, 24px); } }
  .fullcontent h5 {
    font-size: clamp(20px, 1.25rem, 24px); }
    @media screen and (max-width: 576px) {
      .fullcontent h5 {
        font-size: clamp(16px, 1.04167rem, 20px); } }
  .fullcontent .row {
    @apply mb-0 {} }
