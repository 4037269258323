.home-info
	@apply bg-blue-E6EFF7
	.block-title
		@apply mb-8
		@screen lg
			margin-bottom: r(40px)
	.swiper-slide
		&:nth-child(even)
			.news-figure
				@screen lg
					@apply flex-col-reverse
	.news-figure
		.zone-time
			@media screen and ( max-width: 576px)
				@apply col-hor
				time
					@apply mb-1
				.zone-type
					@apply pl-0
					&::before
						@apply opacity-0
		.desc
			@screen lg
				+clamp(15px,18px,1.4,4)
	.zone-type
		display: none !important
.news-figure
	@apply bg-white rounded-[20px] overflow-hidden col-ver
	.img
		@apply w-full block
		a
			@apply w-full block
			+img-ratio(230/304)
	.txt
		@apply w-full
		padding: r(24px)
		@screen lg
			padding: r(30px) r(24px)
	.zone-time
		font-size: 14px
		*
			font-size: 14px
	.headline
		padding: r(16px) 0
		a
			@media screen and ( max-width: 1024px)
				+clamp(20px,24px,1.2,2)
			@screen lg
				+clamp(20px,24px,1.2,2)
	.desc
		@media screen and ( max-width: 1024px)
			+clamp(15px,18px,1.4,2)
		@screen lg
			+clamp(15px,18px,1.4,5)
	&:hover
		.headline
			a
				@apply text-green-primary