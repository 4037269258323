.faq-display
	.block-wrap
		@apply bg-white
		padding: r(30px)
		box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.10)
		@screen lg
			padding: r(40px) r(30px)
.toggle-item
	@apply overflow-hidden
	&.active
		.title
			@apply text-green-primary border-b-green-primary
			*
				@apply text-green-primary
			strong
			
			span
				-webkit-line-clamp: unset
			em
				&:before
					content: '\f068'
		.article
			@apply block
	.title
		@apply gap-item bg-white transition py-3 border-b border-b-grey-500
		min-height: 45px
		@screen lg
			@apply py-2
			min-height: 52px
		p
			@apply start-item w-full m-0 font-Judson
		strong
			@apply mr-3 font-bold text-grey-700  font-Judson
			+fz(16px,20px)
		span
			@apply text-grey-700  font-normal font-Judson
			+fz(16px,20px)
		em
			@apply text-grey-700 ml-5 inline-block
			font-size: 18px
			height: 18px
			width: 16px
			transition: .3s all  ease-in-out
			@media screen and ( max-width: 1024px)
				font-size: 16px
				height: 16px
				width: 16px
	.article
		@apply hidden  bg-white text-grey-700  pt-3 
		padding: r(24px) 0
		+fz(15px,18px)
		@screen lg
			padding: r(30px) 0
		*
			@apply text-grey-700
			+fz(15px,18px)
		p
			@apply block mb-4   
		ul
			@apply mt-4
			padding-left: 20px
			list-style-type: disc
			li
				@apply mb-4   
		strong,h3,h4
			@apply font-bold
		img
			@apply block mx-auto my-4
		ul
			list-style-type: disc
			li
				@apply mb-2